import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '..'
import { ThemeStorage } from '@/plugins/StorageHelper'

@Module()
class ThemeStore extends VuexModule {
  public appTheme = 'dark'

  /**
   * get the currently used app theme
   * @returns Theme identifier
   */
  get theme (): string {
    return this.appTheme
  }

  /**
   * Toggle theme.
   *
   * This method triggers the mutation to set the app theme.
   */
  @Action changeTheme (theme: string): void {
    this.setTheme(theme)
  }

  /**
   * Set the initial app theme.
   */
  @Action initializeTheme (): void {
    const themeStorage = new ThemeStorage()
    const themeConfig = themeStorage.config
    if (themeConfig.theme !== null) {
      this.setTheme(themeConfig.theme)
    } else {
      const defaultTheme = 'light'
      this.setTheme(defaultTheme)
    }
  }

  /**
   * Set a new app theme
   * @param theme The new theme state.
   */
  @Mutation setTheme (theme: string): void {
    this.appTheme = theme
    const themeStorage = new ThemeStorage()
    themeStorage.addTheme(this.appTheme)
  }
}

export default new ThemeStore({ store: store, name: 'theme' })
