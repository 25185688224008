

















import { Component, Vue } from 'vue-property-decorator'
import SidebarStore from '@/store/modules/SidebarStore'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import SubHeader from '@/components/Sidebar/SubHeader.vue'
import Header from '@/components/Header.vue'
import DashboardContent from './DashboardContent.vue'

@Component({
  components: {
    Header,
    SubHeader,
    Sidebar,
    DashboardContent
  }
})
export default class DashboardLayoutVueComponent extends Vue {
  get isSidebarCollapsed (): boolean {
    return SidebarStore.collapsed
  }
}
