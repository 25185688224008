import constants from '@/common/constants'
import { FirebaseApp, FirebaseOptions } from 'firebase/app'
import { getAuth, sendEmailVerification, signOut } from 'firebase/auth'
import { getDatabase, ref, get, onValue, off, DatabaseReference, update } from 'firebase/database'
import { FirebaseError, Unsubscribe } from '@firebase/util'
import router from '@/router'
import { FirebaseAppInstance } from '@/data/app'
import { authenticationStore, AdministratorUser, RelatedUser } from '@/store/modules/authenticationStore'
import SetupStore from '@/store/modules/SetupStore'
import CompanyStore from '@/store/modules/CompanyStore'
import { CompanyHandler } from '@/data/company'

export interface IUser {
  email: string
}

interface DefaultDbUser {
  account: string
  created: number
  database: string
  mapquestKey: string
  storage: string
}

export class AuthenticationInstance {
  private static instance: AuthenticationInstance
  private currentAppListeners: Array<string> = []
  private onDefaultAuthStateChanged?: Unsubscribe
  private onRelatedAuthStateChanged?: Unsubscribe
  private usersDbReference?: DatabaseReference
  private administratorsDbReference?: DatabaseReference
  private currentUid = ''

  /**
   * Create | get the current authentication singelton instance.
   *
   * @returns The singleton instance.
   */
  public static getInstance (): AuthenticationInstance {
    if (!AuthenticationInstance.instance) {
      AuthenticationInstance.instance = new AuthenticationInstance()
    }
    return AuthenticationInstance.instance
  }

  /**
   * Listen to the `onAuthStateChange`-event on the [DEFAULT] app.
   *
   * If the user is signed in, this method will also create the [RELATED] app
   * and starts the listener for the `onAuthStateChange`-event on the [RELATED]
   * app.
   */
  public listenOnDefaultAuthState (): void {
    const appDefault = FirebaseAppInstance.getInstance().getApp()
    if (this.isListeningForApp(appDefault) || this.onDefaultAuthStateChanged) return
    this.currentAppListeners.push(appDefault.name)

    const auth = getAuth(appDefault)
    this.onDefaultAuthStateChanged = auth.onAuthStateChanged(user => {
      if (user) {
        // user is signed in in [DEFAULT] app.
        this.loadDefaultUserData(appDefault, user.uid).then((defaultUser) => {
          if (defaultUser) {
            SetupStore.setValidationKey(defaultUser.account)
            SetupStore.setMapquestKey(defaultUser.mapquestKey)
            const firebaseRelatedConfig: FirebaseOptions = {
              apiKey: process.env.VUE_APP_API_KEY,
              appId: process.env.VUE_APP_APP_ID,
              projectId: process.env.VUE_APP_PROJECT_ID,
              authDomain: process.env.VUE_APP_AUTH_DOMAIN,
              databaseURL: defaultUser.database,
              storageBucket: defaultUser.storage
            }
            const app = FirebaseAppInstance.getInstance().addFirebaseApp(firebaseRelatedConfig, constants.APP_RELATED)
            if (app && app.name === constants.APP_RELATED && !this.isListeningForApp(app)) {
              if (!authenticationStore.signingIn) {
                this.listenOnRelatedAuthState()
              }
            }
          }
        })
      } else {
        // User is not signed in in [DEFAULT] app.
        const app = FirebaseAppInstance.getInstance().getAppNamed(constants.APP_RELATED)
        if (app) {
          this.signOutFromApp(app)
        }
      }
    })
  }

  /**
   * Listen to the `onAuthStateChange`-event on the [DEFAULT] app.
   *
   * If the user is signed in, this method will also create the [RELATED] app
   * and starts the listener for the `onAuthStateChange`-event on the [RELATED]
   * app.
   */
  public listenOnRelatedAuthState (): void {
    const appRelated = FirebaseAppInstance.getInstance().getAppNamed(constants.APP_RELATED)
    if (appRelated) {
      if (this.isListeningForApp(appRelated) || this.onRelatedAuthStateChanged) return
      this.currentAppListeners.push(appRelated.name)

      const auth = getAuth(appRelated)
      this.onRelatedAuthStateChanged = auth.onAuthStateChanged(user => {
        if (user) {
          // user is signed in in [RELATED] app.
          AuthenticationInstance.redirectFromSignin()
          this.currentUid = user.uid
          authenticationStore.setUser(user)
          authenticationStore.setRelatedConnected(true)
          this.startUserDbListener(appRelated, this.currentUid)
          this.startAdministratorDbListener(appRelated)
          CompanyHandler.loadCompanyDataOnce()
        } else {
          // user is not signed in in [RELATED] app.
          this.stopUserDbListener()
          this.stopAdministratorDbListener()
          this.currentUid = ''
          authenticationStore.setUser(null)
          authenticationStore.setRelatedConnected(false)
          FirebaseAppInstance.getInstance().deleteApp(appRelated).then(() => {
            const index = this.currentAppListeners.indexOf(constants.APP_RELATED, 0)
            if (index > -1) {
              this.currentAppListeners.splice(index, 1)
            }
          })
          this.onRelatedAuthStateChanged = undefined
          AuthenticationInstance.redirectToSignin()
        }
      })
    }
  }

  /**
   * Start listening for changes in the database belonging to the signed in
   * user.
   * @param app The firebase app.
   * @param uid The currently signed in user's uid.
   */
  private startUserDbListener (app: FirebaseApp, uid: string): void {
    this.stopUserDbListener()
    const db = getDatabase(app)
    this.usersDbReference = ref(db, `${constants.DB_USERS}/${uid}`)
    onValue(this.usersDbReference, (snapshot) => {
      const relatedUser = snapshot.val() as RelatedUser | null
      authenticationStore.setRelatedUser(relatedUser)
    })
  }

  /**
   * Stop listening for changes in the database belonging to the signed in user.
   */
  private stopUserDbListener (): void {
    authenticationStore.setRelatedUser(null)
    if (this.usersDbReference) {
      off(this.usersDbReference)
    }
  }

  /**
   * Start listening for changes in the database belonging to the administrator
   * users.
   * @param app The firebase app.
   */
  private startAdministratorDbListener (app: FirebaseApp): void {
    this.stopAdministratorDbListener()
    const db = getDatabase(app)
    this.administratorsDbReference = ref(db, `${constants.DB_ADMINISTRATORS}`)
    onValue(this.administratorsDbReference, (snapshot) => {
      if (snapshot.exists()) {
        const administratorUsers: Array<AdministratorUser> = []
        snapshot.forEach((admin) => {
          administratorUsers.push({
            key: admin.key,
            isAdministrator: admin.val() as boolean
          })
        })
        authenticationStore.setAdministratorUsers(administratorUsers)
      } else {
        authenticationStore.setAdministratorUsers([])
      }
    })
  }

  /**
   * Stop listening for changes in the database belonging to the administrator
   * users.
   */
  private stopAdministratorDbListener (): void {
    authenticationStore.setAdministratorUsers([])
    if (this.administratorsDbReference) {
      off(this.administratorsDbReference)
    }
  }

  /**
   * Load the users database snapshot once.
   *
   * @param app The firebase app.
   * @param uid The users uid.
   * @returns The users database snapshot.
   */
  private async loadDefaultUserData (app: FirebaseApp, uid: string): Promise<DefaultDbUser | null> {
    const db = getDatabase(app)
    const usersRef = ref(db, `${constants.DB_USERS}/${uid}`)
    return await get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as DefaultDbUser
      }
      return null
    })
  }

  /**
   * Check if the AuthenticationInstance already listens on the auth state of
   * the given app.
   *
   * @param app The firebase app.
   * @returns `true`, if the apps name is contained in the `currentAppListener`.
   */
  private isListeningForApp (app: FirebaseApp): boolean {
    return this.currentAppListeners.indexOf(app.name) > -1
  }

  public async resendVerificationMail (): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getAppNamed(constants.APP_RELATED)
    if (app) {
      const auth = getAuth(app)
      const user = auth.currentUser
      if (user) {
        return await sendEmailVerification(user)
      } else {
        return Promise.reject(new FirebaseError('auth/no-user', 'no user found'))
      }
    } else {
      return Promise.reject(new FirebaseError('app/no-app', 'no app found'))
    }
  }

  public async convertAccountToAdministrator (overrideConfirmed = false): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getAppNamed(constants.APP_RELATED)
    if (app && this.currentUid !== '') {
      const db = getDatabase(app)
      const accountsRef = ref(db)

      // eslint-disable-next-line
      const administratorUpdates: Record<string, any> = {}
      administratorUpdates[constants.DB_ADMINISTRATORS + '/' + this.currentUid] = true

      // eslint-disable-next-line
      const userUpdates: Record<string, any> = {}
      userUpdates[constants.DB_USERS + '/' + this.currentUid + '/role'] = 'admin'
      if (overrideConfirmed) {
        userUpdates[constants.DB_USERS + '/' + this.currentUid + '/confirmed'] = true
      }

      await update(accountsRef, administratorUpdates)
      await update(accountsRef, userUpdates)
      return Promise.resolve()
    } else {
      return Promise.reject(new FirebaseError('app/no-app', 'no app found'))
    }
  }

  /**
   * Sign out the user from the given app.
   *
   * This will also clear the vuex store values.
   *
   * @param app The firebase app.
   */
  public async signOutFromApp (app?: FirebaseApp): Promise<void> {
    CompanyStore.setCompany(null)
    SetupStore.clearSetupData()
    const auth = getAuth(app)
    return signOut(auth)
  }

  /**
   * Redirect from sign in page.
   *
   * If set, this method redirects the route to the page given by the `redirect`
   * query in the url.
   */
  public static redirectFromSignin (): void {
    if (router.currentRoute.query.redirect) {
      const { redirect, ...q } = router.currentRoute.query
      router.push({
        path: redirect.toString(),
        query: q
      })
    } else if (router.currentRoute.name === 'signin') {
      router.push({
        name: 'dashboard',
        query: router.currentRoute.query
      })
    }
  }

  /**
   * Redirect to sign in page.
   *
   * This method redirects the route to the sign in page.
   */
  public static redirectToSignin (): void {
    if (router.currentRoute.name !== 'signin' && router.currentRoute.meta?.requiresAuth) {
      const q = router.currentRoute.query
      q.redirect = router.currentRoute.path
      router.push({
        name: 'signin',
        query: q
      })
    }
  }
}
