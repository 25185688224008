

























import { Component, Vue } from 'vue-property-decorator'
import CardLoadingOverlay from '@/components/overlays/CardLoadingOverlay.vue'
import LocalizationDropdown from '@/components/Localization/LocalizationDropdown.vue'
import SignInForm from '@/components/forms/SignInForm.vue'

@Component({
  components: { CardLoadingOverlay, LocalizationDropdown, SignInForm }
})
export default class SigninVueComponent extends Vue {
  public isLoading = false

  public onSignInLoadingChanged (isLoading: boolean): void {
    this.isLoading = isLoading
  }
}
