















import { Component, Vue } from 'vue-property-decorator'
import MediaListSkeleton from '@/components/skeletons/MediaListSkeleton.vue'
import MediaList from '@/components/MediaList/MediaList.vue'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import { authenticationStore } from '@/store/modules/authenticationStore'
import SetupStore from '@/store/modules/SetupStore'

@Component({
  components: { MediaListSkeleton, MediaList }
})
export default class ValidationKeyCard extends Vue {
  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }

  get validationKey (): string {
    return SetupStore.validationKey ?? ''
  }

  get validationKeyMediaItems (): Array<IMediaListItem> {
    return [{
      key: '01',
      localeTitle: 'validationKey.titleYours',
      subtitle: this.validationKey,
      icon: 'key',
      variant: 'info',
      iconSize: '3rem',
      isClickable: false
    }]
  }
}
