

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { IMediaListItem } from './lib/MediaList'
import MediaListItem from './MediaListItem.vue'

@Component({
  components: { MediaListItem }
})
export default class MediaList extends Vue {
  @Prop() mediaListItems!: IMediaListItem[]
  @Prop() title!: string
  @Prop() localeTitle!: string
  @Prop({ default: 'componentMediaList.noData' }) noDataTextLocaleKey!: string

  public onSelectedMediaItem (key: string): void {
    this.$forceUpdate()
    this.$emit('selected-media-item', key)
  }
}
