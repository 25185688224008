import constants from '@/common/constants'
import { getDatabase, ref, DatabaseReference, DataSnapshot, onChildAdded, onChildChanged, onChildRemoved, off, update, child, set, serverTimestamp, increment, query, orderByChild, equalTo, Query } from 'firebase/database'
import moment from 'moment'
import { FirebaseAppInstance } from '@/data/app'
import { EDriverStatus, IDriver } from './driver'
import { Unsubscribe } from '@firebase/util'
// import { ResponseError } from '@/data/error'

export class DriversHandler {
  private driversRef: DatabaseReference
  private driversQuery: Query
  // eslint-disable-next-line
  private onDriverAdded?: Unsubscribe
  // eslint-disable-next-line
  private onDriverChanged?: Unsubscribe
  // eslint-disable-next-line
  private onDriverRemoved?: Unsubscribe
  public drivers: Array<IDriver>

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.driversRef = ref(db, constants.DB_USERS)
    this.driversQuery = query(this.driversRef, orderByChild('role'), equalTo('driver'))
    this.drivers = []
  }

  public startDriversListener (): void {
    // Child added
    this.onDriverAdded = onChildAdded(this.driversQuery, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        this.drivers.push(this.convertSnapshotToDriver(snapshot, snapshot.key))
      }
    })

    // Child changed
    this.onDriverChanged = onChildChanged(this.driversQuery, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const index = this.drivers.map(driver => driver.key).indexOf(snapshot.key)
        if (index > -1) {
          const newDriverData = this.convertSnapshotToDriver(snapshot, snapshot.key)
          this.drivers.splice(index, 1, newDriverData)
        }
      }
    })

    // Child removed
    this.onDriverRemoved = onChildRemoved(this.driversQuery, (snapshot) => {
      if (snapshot.key) {
        const index = this.drivers.map(driver => driver.key).indexOf(snapshot.key)
        if (index > -1) {
          this.drivers.splice(index, 1)
        }
      }
    })
  }

  public stopDriversListener (): void {
    off(this.driversQuery)
    // this.driversRef.off('child_added', this.onDriverAdded)
    // this.driversRef.off('child_changed', this.onDriverChanged)
    // this.driversRef.off('child_removed', this.onDriverRemoved)
  }

  // eslint-disable-next-line
  public async updateDriverProperties (key: string, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const driverRef = ref(db, constants.DB_USERS)

    await update(child(driverRef, key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  /**
   * Confirm a new created driver account.
   *
   * This methods set the `active` and `confirmed` state of a driver account to
   * `true`. Furthermore the driver statistics will be incremented by 1.
   * @param key The driver's database key.
   * @returns Promise.
   */
  public async confirmDriverAccount (key: string): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const statisticsRef = ref(db, constants.DB_STATISTICS)

    await this.updateDriverProperties(key, { confirmed: true, active: true })

    await set(child(child(statisticsRef, 'drivers'), 'count'), increment(1))
    return Promise.resolve()
  }

  /**
   * Change the active state of a driver.
   *
   * The `active` state determines whether an account is blocked or not.
   * @param key The driver's database key.
   * @param newState The new active state.
   * @returns Promise.
   */
  public async changeActiveState (key: string, newState: boolean): Promise<void> {
    await this.updateDriverProperties(key, { active: newState })
    return Promise.resolve()
  }

  /**
   * Convert a firebase DataSnapshot to an IDriver object.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted IDriver object.
   */
  private convertSnapshotToDriver (snapshot: DataSnapshot, key: string): IDriver {
    const isActive = Boolean(snapshot.val().active) ?? false
    const isConfirmed = Boolean(snapshot.val().confirmed) ?? false
    const isOnline = Boolean(snapshot.val().online) ?? false

    let status: EDriverStatus = EDriverStatus.offline
    if (!isConfirmed) {
      status = EDriverStatus.confirm
    } else if (!isActive) {
      status = EDriverStatus.blocked
    } else {
      status = isOnline ? EDriverStatus.online : EDriverStatus.offline
    }

    return {
      key: key,
      created: moment(snapshot.val().created, 'x'),
      firstname: snapshot.val().firstname,
      lastname: snapshot.val().lastname,
      image: snapshot.val().image,
      phone: snapshot.val().phone,
      email: snapshot.val().email,
      lastOnline: moment(snapshot.val().lastOnline, 'x'),
      status: status,
      isDriving: false
    }
  }
}
