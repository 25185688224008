import constants from '@/common/constants'
import { FirebaseApp, FirebaseOptions } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, UserCredential, User } from 'firebase/auth'
import { getDatabase, ref, child, get, DataSnapshot } from 'firebase/database'
import { ResponseError } from '@/data/error'
import { FirebaseAppInstance } from '@/data/app'
import { AuthenticationInstance } from './authenticationInstance'

export class Signin {
  // Public members
  public email: string
  public password: string

  // Private members
  private firebaseAppInstance: FirebaseAppInstance
  private authenticationInstance: AuthenticationInstance

  // Constructor
  constructor (email: string, password: string) {
    this.email = email
    this.password = password

    this.firebaseAppInstance = FirebaseAppInstance.getInstance()
    this.authenticationInstance = AuthenticationInstance.getInstance()
  }

  /**
   * Sign in the user using the given `email` and `password` properties.
   *
   * 1. Sign out from [DEFAULT] app.
   * 2. Sign in into [DEFAULT] app.
   * 3. Check if email is verified.
   * 4. Load the corresponding [RELATED] app data.
   * 5. Create firebase [RELATED] app.
   * 6. Sign in into [RELATED] app.
   * 7. Check account role.
   * 8. Start listening on the related auth state.
   * @returns Promise
   */
  public async signin (): Promise<void> {
    return new Promise((resolve, reject) => {
      // 1. Sign out from [DEFAULT] app.
      console.log('###### sign out from default app')
      this.authenticationInstance.signOutFromApp().then(() => {
        // 2. Sign in into [DEFAULT] app.
        console.log('###### sign in in default')
        return this.signinWithCredentials(this.email, this.password)
      }).then((user) => {
        // 3. Check if email is verified.
        return user.user
        // console.log('###### check email verification')
        // return this.checkEmailVerification(user)
      }).then((user) => {
        // 4. Load the corresponding [RELATED] app data.
        console.log('###### load RELATED data')
        return this.loadRelatedData(user.uid)
      }).then((relatedAccount: DataSnapshot) => {
        // 5. Create firebase [RELATED] app.
        console.log('###### create RELATED app')
        const firebaseRelatedConfig: FirebaseOptions = {
          apiKey: process.env.VUE_APP_API_KEY,
          appId: process.env.VUE_APP_APP_ID,
          projectId: process.env.VUE_APP_PROJECT_ID,
          authDomain: process.env.VUE_APP_AUTH_DOMAIN,
          databaseURL: relatedAccount.val().database,
          storageBucket: relatedAccount.val().storage
        }
        return this.firebaseAppInstance.addFirebaseApp(firebaseRelatedConfig, constants.APP_RELATED)
      }).then((relatedApp: FirebaseApp) => {
        // 6. Sign in into [RELATED] app.
        console.log('###### sign in into RELATED')
        return this.signinWithCredentials(this.email, this.password, relatedApp)
      }).then((user) => {
        // 7. Check account role.
        return user
        // console.log('###### check role')
        // return this.checkRelatedRole(user)
      }).then(() => {
        // 8. Start listening on the related auth state.
        console.log('###### start listening on related auth state')
        this.authenticationInstance.listenOnRelatedAuthState()
        AuthenticationInstance.redirectFromSignin()
        resolve()
      }).catch((error) => {
        this.authenticationInstance.signOutFromApp()
        if (error.code) {
          error.name = error.code
        }
        reject(new ResponseError(error.name, error.message))
      })
    })
  }

  public resendVerificationMail (): void {
    // TODO: Think of switching the `isVerified` check to the pages behind sign
    // in, because resending requires to be signed in.
  }

  /**
   * Sign in the user into the corresponding app.
   *
   * @param email The users email address.
   * @param password The users password.
   * @param app The firebase app.
   * @returns Firebase UserCredential object.
   */
  private async signinWithCredentials (
    email: string, password: string, app?: FirebaseApp
  ): Promise<UserCredential> {
    const auth = getAuth(app)
    return await signInWithEmailAndPassword(auth, email, password)
  }

  /**
   * Check if the user's email is verified.
   *
   * @param user The firebase user.
   * @returns The user on fulfilled (resolve).
   */
  private async checkEmailVerification (user: UserCredential): Promise<User> {
    if (user.user) {
      if (user.user.emailVerified) {
        return Promise.resolve(user.user)
      }
      return Promise.reject(new ResponseError('auth/email-not-verified', 'The corresponding email address is not yet verified.'))
    }
    return Promise.reject(new ResponseError('auth/unexpected-error', 'user.user is not set'))
  }

  /**
   * Load the users database values.
   *
   * @param uid The users uid.
   * @returns The firebase user snapshot.
   */
  private async loadRelatedData (uid: string): Promise<DataSnapshot> {
    const db = getDatabase()
    const dbRef = ref(db)
    const relatedAccount = await get(child(dbRef, `${constants.DB_USERS}/${uid}`)).then((snapshot) => {
      return snapshot
    })

    if (relatedAccount.exists()) {
      return Promise.resolve(relatedAccount)
    }
    return Promise.reject(
      new ResponseError(
        'auth/no-default-signup-user',
        'The user account is not authorized to use this area.'
      )
    )
  }

  /**
   * Get the users role.
   *
   * @param user The firebase UserCredential object.
   * @returns The firebase User.
   */
  private async checkRelatedRole (user: UserCredential): Promise<User> {
    const relatedApp = FirebaseAppInstance.getInstance().getAppNamed(constants.APP_RELATED)
    if (!relatedApp) {
      return Promise.reject(new ResponseError('auth/unexpected-error', ''))
    }
    if (user.user) {
      const db = getDatabase()
      const dbRef = ref(db)
      const role = await get(child(dbRef, `${constants.DB_USERS}/${user.user.uid}/role`)).then((snapshot) => {
        return snapshot.val()
      })

      if (role === 'admin') {
        return Promise.resolve(user.user)
      } else if (role === 'driver') {
        return Promise.reject(
          new ResponseError('auth/driver-account', 'Your account has not yet been verified by an administrator.')
        )
      } else if (role === 'pending-admin') {
        return Promise.reject(
          new ResponseError('auth/admin-pending', 'Your account has not yet been verified by an administrator.')
        )
      } else {
        return Promise.reject(new ResponseError('auth/no-role', 'No role is assigned to your account'))
      }
    }
    return Promise.reject(new ResponseError('auth/unexpected-error', 'user.user is not set'))
  }
}
