import { Action, Mutation, VuexModule } from 'vuex-class-modules'
import { authenticationStore } from '../modules/authenticationStore'

interface KeyedData {
  key: string
}

export interface IDataStore<T> {
  // States
  isRestricted: boolean
  registeredComponents: Array<string>
  data: Array<T>
  unwatch: unknown

  // Actions
  onRestrictionChanged (value: boolean): void
  registerComponent (name?: string): void
  unregisterComponent (name?: string): void
  watchRestriction (): void
  unwatchRestriction (): void

  // Mutations
  // eslint-disable-next-line
  addWatcher (watcher: Function): void
  setRestricted (value: boolean): void
  add (data: T): void
  update (newData: T): void
  remove (key: string | null): void
  setData (dataArray: Array<T>): void
  clearData (): void
  addComponent (name: string): void
  deleteComponent (name: string): void
}

export class DataStore<T extends KeyedData> extends VuexModule implements IDataStore<T> {
  public isRestricted = true
  public registeredComponents: Array<string> = []
  public data: Array<T> = []
  public unwatch: unknown = ''

  @Action
  onRestrictionChanged (value: boolean): void {
    this.setRestricted(!value)
    if (this.registeredComponents.length > 0) {
      this.startListening()
    }
  }

  @Action
  registerComponent (name?: string): void {
    if (name) {
      const index = this.registeredComponents.indexOf(name)
      if (index === -1) {
        this.addComponent(name)
        this.watchRestriction()
        this.startListening()
      }
    }
  }

  @Action
  unregisterComponent (name?: string): void {
    if (name) {
      this.deleteComponent(name)
      if (this.registeredComponents.length <= 0) {
        this.unwatchRestriction()
        this.stopListening()
      }
    }
  }

  @Action
  watchRestriction (): void {
    this.unwatchRestriction()
    const watcher = authenticationStore.$watch(
      (authStore) => authStore.isAdministrator,
      (newValue: boolean | null) => {
        this.onRestrictionChanged(newValue ?? false)
      }, { deep: false, immediate: true }
    )
    this.addWatcher(watcher)
  }

  @Action
  unwatchRestriction (): void {
    if (typeof this.unwatch === 'function') {
      this.unwatch()
    }
  }

  @Mutation
  // eslint-disable-next-line
  addWatcher (watcher: Function): void {
    this.unwatch = watcher
  }

  @Mutation
  setRestricted (value: boolean): void {
    this.isRestricted = value
  }

  @Mutation
  add (data: T): void {
    this.data.push(data)
  }

  @Mutation
  update (newData: T): void {
    const index = this.data.map(d => d.key).indexOf(newData.key)
    if (index > -1) {
      this.data.splice(index, 1, newData)
    }
  }

  @Mutation
  remove (key: string | null): void {
    if (key) {
      const index = this.data.map(d => d.key).indexOf(key)
      if (index > -1) {
        this.data.splice(index, 1)
      }
    }
  }

  @Mutation
  setData (dataArray: Array<T>): void {
    this.data = dataArray
  }

  @Mutation
  clearData (): void {
    this.data = []
  }

  @Mutation
  addComponent (name: string): void {
    this.registeredComponents.push(name)
  }

  @Mutation
  deleteComponent (name: string): void {
    const index = this.registeredComponents.indexOf(name)
    if (index > -1) {
      this.registeredComponents.splice(index, 1)
    }
  }

  /**
   * Provide a function to start listening for the generic data `T`.
   *
   * This method must be implemented in any class that extends the `DataStore`.
   */
  protected startListening (): void {
    throw new Error('Method is not implemented.')
  }

  /**
   * Provide a function to stop listening for the generic data `T`.
   *
   * This method must be implemented in any class that extends the `DataStore`.
   */
  protected stopListening (): void {
    throw new Error('Method is not implemented.')
  }
}
