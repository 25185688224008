
















import { Component, Prop, Vue } from 'vue-property-decorator'
import FaqAccordionItem from './lib/FaqAccordionItem'

@Component
export default class FaqAccordionVueComponent extends Vue {
  @Prop() items!: Array<FaqAccordionItem>

  public onClickedHeader (accordionId: string): void {
    this.$root.$emit('bv::toggle::collapse', accordionId)
  }
}
