import constants from '@/common/constants'
import { getDatabase, ref, child, update, serverTimestamp } from 'firebase/database'
import { getAuth, updateProfile } from 'firebase/auth'
import { FirebaseAppInstance } from '@/data/app'

export class ProfileHandler {
  // eslint-disable-next-line
  // public static readonly FIREBASE_TIMESTAMP: any = firebase.database.ServerValue.TIMESTAMP

  /**
   * Update single properties on the vehicle specified by the `key` attribute
   * @param key The vehicle-specific database key.
   * @param data The comma-separated properties to be updated. `{'key': 'value'}`
   * @returns Promise
   */
  // eslint-disable-next-line
  public static async updateUserProperties (key: string, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const usersRef = ref(db, constants.DB_USERS)

    await update(child(usersRef, key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  public static async updateCurrentUser (displayName: string): Promise <void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const auth = getAuth(app)
    const currentUser = auth.currentUser
    if (currentUser) {
      updateProfile(currentUser, {
        displayName: displayName
      }).then(() => {
        const currentDefaultUser = auth.currentUser
        if (currentDefaultUser) {
          updateProfile(currentDefaultUser, {
            displayName: displayName
          })
        }
      })
    }
  }
}
