














import { Component, Vue } from 'vue-property-decorator'
import CreditsContentVueComponent from '@/components/Legal/CreditsContent.vue'

@Component({
  components: { CreditsContentVueComponent }
})
export default class CreditsVueComponent extends Vue {
  get isAdminArea (): boolean {
    return this.$router.currentRoute.name === 'creditsAdmin'
  }

  get redirectComponentName (): string {
    return typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : 'signin'
  }
}
