import { initializeApp, FirebaseOptions, FirebaseApp, getApp, getApps, deleteApp } from 'firebase/app'
import { getDatabase, ref, onValue, connectDatabaseEmulator } from 'firebase/database'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import SetupStore from '@/store/modules/SetupStore'

/**
 * FirebaseAppInstance singleton.
 *
 * This singleton instance cares about the firebase apps.
 */
export class FirebaseAppInstance {
  /**
   * The FirebaseAppInstance singleton property.
   */
  private static instance: FirebaseAppInstance

  /**
   * Default firebase options to create the [DEFAULT] app.
   */
  private static defaultFirebaseOptions: FirebaseOptions = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
  }

  /**
   * The names of all created firebase apps.
   */
  public initializedAppNames: Array<string> = []

  /**
   * Private singleton constructor.
   */
  private constructor () {
    console.log('initialize FirebaseAppInstance')
    this.addFirebaseApp(FirebaseAppInstance.defaultFirebaseOptions)
    this.startConnectionCheck()
  }

  /**
   * FirebaseAppInstance as a singleton.
   *
   * @returns The FirebaseAppInstance singleton.
   */
  public static getInstance (): FirebaseAppInstance {
    if (!FirebaseAppInstance.instance) {
      FirebaseAppInstance.instance = new FirebaseAppInstance()
    }
    return FirebaseAppInstance.instance
  }

  /**
   * Check connection to firebase servers.
   *
   * This methods listens on `.info/connected`-event on the [DEFAULT] database.
   * If the app looses the connection to the firebase server, the `isConnected`
   * state of the `SetupStore` will be set to `false`.
   *
   * Use the `isConnected` state to get in touch of the ability to connect to
   * the firebase server.
   *
   * When a connection could be established for the first time, the
   * `isInitialized` state of the `SetupStore` will be set to `true`.
   */
  private startConnectionCheck (): void {
    console.log('### start connection check')
    const db = getDatabase()
    const connectedRef = ref(db, '.info/connected')
    onValue(connectedRef, (snapshot) => {
      if (snapshot.val() === true) {
        // App is connected to the firebase server.
        SetupStore.setInitialized(true)
        SetupStore.setConnected(true)
      } else {
        // App is NOT connected to the firebase server.
        SetupStore.setConnected(false)
      }
    })
  }

  // public getDatabaseInstance (app?: FirebaseApp): Database {
  //   const db = getDatabase(app)
  //   if (location.hostname === 'localhost') {
  //     connectDatabaseEmulator(db, 'localhost', 9000)
  //   }
  //   return getDatabase()
  // }

  /**
   * Add a new Firebase app instance.
   *
   * The initialized firebase app's name will be appended to the
   * `initializedAppNames`-list. If a firebase app with the given `name` already
   * exists, this app will be returned and no other app instance will be created.
   * @param options Options to configure the app's services
   * @param name Optional name of the app to initialize. If no name is provided, the default is `"[DEFAULT]"`.
   * @returns The initialized app.
   */
  public addFirebaseApp (options: FirebaseOptions, name?: string): FirebaseApp {
    const existingApp = getApps().find(app => app.name === name)
    if (existingApp) { return existingApp }
    console.log('### add app: ', name)
    console.log(options)
    const app = initializeApp(options, name)
    const db = getDatabase(app)
    const auth = getAuth(app)
    const storage = getStorage(app)
    if (location.hostname === 'localhost') {
      connectDatabaseEmulator(db, 'localhost', 9000)
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectStorageEmulator(storage, 'localhost', 9199)
    }
    return app
  }

  /**
   * Returns the firebase app instance initialized with the given name.
   *
   * This method will **not** return the `"[DEFAULT]"` firebase app as default.
   * @param name Search name of the firebase app to return.
   * @returns The initialized firebase app if found or `undefined`.
   */
  public getAppNamed (name: string): FirebaseApp | undefined {
    return getApps().find(app => app.name === name)
  }

  /**
   * Returns the firebase app instance initialized with the given name.
   *
   * This method will return the `"[DEFAULT]"` firebase app if no name is
   * specified.
   * @param name Search name of the firebase app to return.
   * @returns The initialized firebase app or `"[DEFAULT]"`.
   */
  public getApp (name?: string): FirebaseApp {
    return getApp(name)
  }

  /**
   * Deletes the firebase app instance initialized with the given name.
   * @param name The firebase app's name.
   */
  public async deleteAppNamed (name: string): Promise<void> {
    const existingApp = this.getAppNamed(name)
    if (existingApp) {
      Promise.resolve(this.deleteApp(existingApp))
    }
  }

  /**
   * Deletes the given firebase app instance.
   * @param app The firebase app.
   */
  public async deleteApp (app?: FirebaseApp): Promise<void> {
    if (app) {
      const appName = app.name
      console.log('### delete app: ', appName)
      await deleteApp(app).then(() => {
        const index = this.initializedAppNames.indexOf(appName, 0)
        if (index > -1) {
          this.initializedAppNames.splice(index, 1)
        }
      })
    }
    return Promise.resolve()
  }
}
