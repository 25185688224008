























import { Component, Vue } from 'vue-property-decorator'
import LocalizationStore from '@/store/modules/LocalizationStore'

@Component
export default class LocalizationDropdownVueComponent extends Vue {
  public countryIconProps = { height: 24, width: 24 }

  /**
   * Switch the global app locale.
   */
  public switchLocale (localeKey: string): void {
    LocalizationStore.changeLocale(localeKey)
  }
}
