










































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { CompanyHandler, CompanyObject } from '@/data/company'
import { ResponseError } from '@/data/error'

@Component
export default class EditCompanyForm extends Vue {
  @Prop() company!: CompanyObject
  // @Prop() companyName!: string
  // @Prop() phone!: string
  // @Prop() fax!: string
  // @Prop() email!: string
  // @Prop() address!: string
  // @Prop() website!: string

  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public formCompany = this.company?.name ?? ''
  public formPhone = this.company?.phone ?? ''
  public formFax = this.company?.fax ?? ''
  public formEmail = this.company?.email ?? ''
  public formAddress = this.company?.address ?? ''
  public formWebsite = this.company?.website ?? ''

  get stateCompanyName (): false | null {
    return this.formCheck.findFormError('company') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitEditCompany (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      CompanyHandler.updateCompanyProperties({
        name: this.formCompany,
        phone: this.formPhone,
        fax: this.formFax,
        email: this.formEmail,
        address: this.formAddress,
        website: this.formWebsite
      }).then(() => {
        this.$emit('cancelled-edit-company-form')
        CompanyHandler.loadCompanyDataOnce()
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        this.responseError = responseError.getDatabaseErrorLocaleKey()
        this.showResponseAlert = true
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('company', this.formCompany)
    this.formCheck.checkLengthGreater('company', this.formCompany, 2)
    this.formCheck.checkLengthLessThan('company', this.formCompany, 128)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.formCompany = this.company?.name ?? ''
    this.formPhone = this.company?.phone ?? ''
    this.formFax = this.company?.fax ?? ''
    this.formEmail = this.company?.email ?? ''
    this.formAddress = this.company?.address ?? ''
    this.formWebsite = this.company?.website ?? ''
    this.formCheck.clearFormErrors()
    this.showResponseAlert = false
  }

  /**
   * Cancel form. This method will emit the `cancelled-edit-company-form`-event.
   */
  public cancelForm (): void {
    this.resetForm()
    this.$emit('cancelled-edit-company-form')
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
