

























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { authenticationStore } from '@/store/modules/authenticationStore'
import { Signup } from '@/data/authentication'
import { ResponseError } from '@/data/error'
import FormCheck from '@/common/formCheck'

@Component
export default class SignUpForm extends Vue {
  public validationKey = ''
  public email = ''
  public firstname = ''
  public lastname = ''
  public phone = ''
  public password = ''
  public passwordConfirm = ''
  public privacy = false
  public formCheck: FormCheck = new FormCheck()
  public responseError: string | null = null
  public showResponseAlert = false
  public showSuccessAlert = false
  public isLoading = false

  get stateValidationKey (): false | null {
    return this.formCheck.findFormError('validationKey') ? false : null
  }

  get stateEmail (): false | null {
    return this.formCheck.findFormError('email') ? false : null
  }

  get stateFirstname (): false | null {
    return this.formCheck.findFormError('firstname') ? false : null
  }

  get stateLastname (): false | null {
    return this.formCheck.findFormError('lastname') ? false : null
  }

  get statePhone (): false | null {
    return this.formCheck.findFormError('phone') ? false : null
  }

  get statePassword (): false | null {
    return this.formCheck.findFormError('password') ? false : null
  }

  get statePasswordConfirm (): false | null {
    return this.formCheck.findFormError('passwordConfirm') ? false : null
  }

  get statePrivacy (): false | null {
    return this.formCheck.findFormError('privacy') ? false : null
  }

  /**
   * Submit sign up form
   *
   * This methods calls the `signUp` method, if all input values are valid.
   */
  public submitSignupForm (): void {
    if (!this.checkForm()) {
      this.signup()
    }
  }

  /**
   * sign up with provided data
   */
  public signup (): void {
    this.setLoading(true)
    authenticationStore.setSigningUp(true)
    const signup = new Signup({
      validationKey: Number(this.validationKey),
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone,
      password: this.password
    })

    signup.signup().then(() => {
      this.clearForm(true)
      this.showSuccessAlert = true
    }).catch((error: ResponseError) => {
      this.responseError = error.getErrorLocaleKey()
      this.showResponseAlert = true
    }).finally(() => {
      this.setLoading(false)
      authenticationStore.setSigningUp(false)
    })
  }

  /**
   * Check the form input against rules before submitting
   */
  public checkForm (): boolean {
    this.clearForm()
    this.formCheck.checkTextRequired('validationKey', this.validationKey)
    this.formCheck.checkLengthEqual('validationKey', this.validationKey, 5)
    this.formCheck.checkIsNumber('validationKey', this.validationKey)
    this.formCheck.checkTextRequired('email', this.email)
    this.formCheck.checkEmailFormat('email', this.email)
    this.formCheck.checkTextRequired('firstname', this.firstname)
    this.formCheck.checkTextRequired('lastname', this.lastname)
    this.formCheck.checkTextRequired('phone', this.phone)
    this.formCheck.checkTextRequired('password', this.password)
    this.formCheck.checkLengthGreater('password', this.password, 6)
    this.formCheck.checkTextRequired('passwordConfirm', this.passwordConfirm)
    this.formCheck.checkInputEquals('passwordConfirm', this.passwordConfirm, this.password)
    this.formCheck.checkCheckboxChecked('privacy', this.privacy)
    return this.formCheck.hasErrors()
  }

  /**
   * Clear all form properties.
   *
   * @param clearInputs Whether to clear inputs as well.
   */
  public clearForm (clearInputs = false): void {
    this.formCheck.clearFormErrors()
    this.responseError = null
    this.showResponseAlert = false

    if (clearInputs) {
      this.validationKey = ''
      this.email = ''
      this.firstname = ''
      this.lastname = ''
      this.phone = ''
      this.password = ''
      this.passwordConfirm = ''
      this.privacy = false
    }
  }

  /**
   * Set a value for the `isLoading` property.
   *
   * This method will emit the `sign-up-is-loading`-event.
   */
  public setLoading (isLoading: boolean): void {
    this.isLoading = isLoading
    this.$emit('sign-up-is-loading', this.isLoading)
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
