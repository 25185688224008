




































































































































import { Component, Vue } from 'vue-property-decorator'
import ChangePasswordForm from '@/components/forms/ChangePasswordForm.vue'
import EditProfileForm from '@/components/forms/EditProfileForm.vue'
import EditCompanyForm from '@/components/forms/EditCompanyForm.vue'
import UploadProfileImageForm from '@/components/forms/UploadProfileImageForm.vue'
import ValidationKeyCard from '@/components/validationKey/ValidationKeyCard.vue'
import CompanyStore from '@/store/modules/CompanyStore'
import { ProfileHandler } from '@/data/profile'
import { CompanyHandler, CompanyObject } from '@/data/company'
import { serverTimestamp } from 'firebase/database'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: {
    ChangePasswordForm,
    UploadProfileImageForm,
    EditProfileForm,
    EditCompanyForm,
    ValidationKeyCard
  }
})
export default class ProfileVueComponent extends Vue {
  public profileImagesDbReference = 'profileImages'
  public showEditProfileForm = false
  public showEditCompanyForm = false

  get uid (): string {
    return authenticationStore.user?.uid ?? ''
  }

  get email (): string {
    return authenticationStore.user?.email ?? ''
  }

  get emailVerified (): boolean {
    return authenticationStore.user?.emailVerified ?? false
  }

  get phoneNumber (): string {
    return authenticationStore.relatedUser?.phone ?? ''
  }

  get firstname (): string {
    return authenticationStore.relatedUserData?.firstname ?? ''
  }

  get lastname (): string {
    return authenticationStore.relatedUserData?.lastname ?? ''
  }

  get fullname (): string {
    return `${this.firstname} ${this.lastname}`
  }

  get initials (): string | null {
    const first = authenticationStore.relatedUserData?.firstname?.charAt(0)
    const last = authenticationStore.relatedUserData?.lastname?.charAt(0)
    return `${first}${last}`
  }

  get imageSrc (): string {
    return authenticationStore.relatedUserData?.imageSrc ?? ''
  }

  get role (): string {
    const relatedUserData = authenticationStore.relatedUserData
    if (relatedUserData) {
      switch (relatedUserData.role) {
        case 'admin':
          return 'componentProfile.roleAdmin'
        case 'pending-admin':
          return 'componentProfile.roleAdminPending'
        case 'driver':
          return 'componentProfile.roleDriver'
        default:
          return 'componentProfile.roleNone'
      }
    }
    return 'componentProfile.roleNone'
  }

  get company (): CompanyObject | null {
    return CompanyStore.company
  }

  get companyCreatedDate (): string {
    return CompanyStore.createdMoment ? CompanyStore.createdMoment.format('DD. MMMM YYYY') : ''
  }

  /**
   * Triggered event when the image upload has finished successfully.
   */
  public onFinishedImageUpload (key: string, imageUrl: string): void {
    ProfileHandler.updateUserProperties(key, {
      imageSrc: imageUrl,
      imageDate: serverTimestamp()
    }).then(() => {
      this.$root.$emit('bv::toggle::collapse', 'collapse-image-edit')
    })
  }

  /**
   * Triggered event when `collapse-image-edit` was cancelled by the user.
   */
  public onCancelledImageForm (): void {
    this.$root.$emit('bv::toggle::collapse', 'collapse-image-edit')
  }

  /**
   * Triggered event when `collapse-profile-edit` was cancelled by the user.
   */
  public onCancelledEditProfileForm (): void {
    this.$root.$emit('bv::toggle::collapse', 'collapse-profile-edit')
  }

  /**
   * Triggered event when `collapse-company-edit` was cancelled by the user.
   */
  public onCancelledEditCompanyForm (): void {
    this.$root.$emit('bv::toggle::collapse', 'collapse-company-edit')
  }

  /**
   * Triggered event when the `collapse-profile-edit` finished closing.
   */
  onProfileEditHidden (): void {
    this.showEditProfileForm = false
  }

  /**
   * Triggered event when the `collapse-company-edit` finished closing.
   */
  onCompanyEditHidden (): void {
    this.showEditCompanyForm = false
  }

  mounted (): void {
    CompanyHandler.loadCompanyDataOnce()
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (collapseId === 'collapse-profile-edit' && isJustShown) {
        this.showEditProfileForm = true
      } else if (collapseId === 'collapse-company-edit' && isJustShown) {
        this.showEditCompanyForm = true
      }
    })
  }
}
