import { Locales, LocaleOptions } from './locales'

// Import language files
import de from './de.json'
import en from './en.json'

/**
 * Class to detect and define possible app languages.
 */
export class Locale {
  /**
   * Get the locales, that are defined in the browser.
   *
   * If no languages are defined, the default app language will
   * be returned, which is 'de'. In case there are more than one
   * languages defined, this method will return the first occurence.
   * @param options - Param to decide whether to use language code only.
   * @returns the language code (ISO-639-1).
   */
  static getLocaleByBrowser (options: LocaleOptions): string {
    const defaultOptions: LocaleOptions = {
      languageCodeOnly: false
    }

    const opt: LocaleOptions = {
      ...defaultOptions,
      ...options
    }

    // Get the language codes defined in the browser 639-1
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages

    // If browser has no language defined
    // return the default language 'de'
    if (!browserLocales) {
      return 'de'
    }

    // Trim all found locales
    const browserLocale = browserLocales.map(locale => {
      const trimmedLocale = locale.trim()

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale
    })[0]

    // Return the first element only
    return browserLocale
  }
}

export const messages = {
  [Locales.DE]: de,
  [Locales.EN]: en
}

export const defaultLocale = Locales.DE
