
























import { Component, Vue } from 'vue-property-decorator'
import SidebarStore from '@/store/modules/SidebarStore'
import SidebarItems from './SidebarItems.vue'

@Component({
  components: { SidebarItems }
})
export default class Sidebar extends Vue {
  /**
   * Get the corresponding Icon for the toggle button based on the `collapsed`
   * state.
   */
  get sidebarToggleIcon (): string {
    return this.isSidebarCollapsed ? 'arrow-bar-right' : 'arrow-bar-left'
  }

  /**
   * Get the current `collapsed` state.
   */
  get isSidebarCollapsed (): boolean {
    return SidebarStore.collapsed
  }

  /**
   * Toggle the sidebar
   */
  public toggleSidebar (): void {
    SidebarStore.toggleCollapsed()
  }
}
