




































































































































import { Component, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
// import { ResponseError } from '@/data/error'

@Component
export default class AddVehicleForm extends Vue {
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public contact = {
    name: '',
    company: '',
    email: '',
    message: '',
    privacy: false
  }

  get stateName (): false | null {
    return this.formCheck.findFormError('name') ? false : null
  }

  get stateCompany (): false | null {
    return this.formCheck.findFormError('company') ? false : null
  }

  get stateEmail (): false | null {
    return this.formCheck.findFormError('email') ? false : null
  }

  get stateMessage (): false | null {
    return this.formCheck.findFormError('message') ? false : null
  }

  get statePrivacy (): false | null {
    return this.formCheck.findFormError('privacy') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitContact (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      // Submit: Use "nodemailer"?
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('name', this.contact.name)
    this.formCheck.checkTextRequired('company', this.contact.company)
    this.formCheck.checkTextRequired('email', this.contact.email)
    this.formCheck.checkEmailFormat('email', this.contact.email)
    this.formCheck.checkTextRequired('message', this.contact.message)
    this.formCheck.checkCheckboxChecked('privacy', this.contact.privacy)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.contact = {
      name: '',
      company: '',
      email: '',
      message: '',
      privacy: false
    }
    this.formCheck.clearFormErrors()
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
