

























import { Component, Vue, Watch } from 'vue-property-decorator'
import '@fullcalendar/core/vdom'
import FullCalendar, { CalendarOptions, EventClickArg } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import deLocale from '@fullcalendar/core/locales/de'
import LocalizationStore from '@/store/modules/LocalizationStore'

@Component({
  components: { FullCalendar }
})
export default class CalendarVueComponent extends Vue {
  private calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
    initialView: 'dayGridMonth',
    locales: [deLocale],
    locale: LocalizationStore.locale,
    editable: true,
    selectable: true,
    weekends: true,
    themeSystem: 'bootstrap',
    eventClick: this.onEventClick,
    events: [
      { id: '10', title: 'All day event', date: new Date(), allDay: true },
      { id: '20', title: 'Timed event', date: '2021-09-04' },
      { id: '30', title: 'Timed event', start: '2021-09-07' }
    ]
  }

  private onEventClick (clickInfo: EventClickArg): void {
    console.log('Event clicked!')
    console.log(clickInfo.event.title)
  }

  private onSomeAction (): void {
    console.log('Some action')
  }

  @Watch('$root.$i18n.locale')
  onLocaleChange (newLocale: string): void {
    // Locale has changed, also change calendars locale
    this.calendarOptions.locale = newLocale
  }
}
