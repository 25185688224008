






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CardLoadingOverlay extends Vue {
  @Prop(Boolean) isShow!: boolean
}
