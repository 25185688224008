

































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ComponentNotFound extends Vue {
  public isAdminArea = true

  public setAdminArea (adminArea: boolean): void {
    this.isAdminArea = adminArea
  }

  public mounted (): void {
    this.setAdminArea(this.$router.currentRoute.name === 'notFoundAdmin')
  }
}
