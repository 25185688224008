const APP_DEFAULT = '[DEFAULT]'
const APP_RELATED = '[RELATED]'
const APP_SIGNUP = '[SIGNUP]'
const DB_ACTIVITIES = 'activities'
const DB_ADMINISTRATORS = 'administrators'
const DB_ADRESSES = 'addresses'
const DB_SENSORS = 'sensors'
const DB_CATEGORIES = 'categories'
const DB_COMPANY = 'company'
const DB_CUSTOMERS = 'customers'
const DB_DELIVERY_SERVICES = 'deliveryServices'
const DB_DEVICES = 'devices'
const DB_NOTIFICATIONS = 'notifications'
const DB_SETTINGS = 'settings'
const DB_STATISTICS = 'statistics'
const DB_TEMPLATES = 'templates'
const DB_TOURS = 'tours'
const DB_USERS = 'users'
const DB_VEHICLES = 'vehicles'
const DB_MGMT_ACCOUNTS = 'accounts'
const DB_MGMT_SENSORS = 'sensors'
const DB_MGMT_USERS = 'users'
const ST_ADDITIONALS = 'additionals'
const ST_ARCHIVES = 'archives'
const ST_PROFILE_IMAGES = 'profileImages'
const ST_SIGNS = 'signs'
const ST_USERS = 'users'
const ST_VEHICLES = 'vehicles'

export default {
  APP_DEFAULT: APP_DEFAULT,
  APP_RELATED: APP_RELATED,
  APP_SIGNUP: APP_SIGNUP,
  DB_ACTIVITIES: DB_ACTIVITIES,
  DB_ADMINISTRATORS: DB_ADMINISTRATORS,
  DB_ADRESSES: DB_ADRESSES,
  DB_SENSORS: DB_SENSORS,
  DB_CATEGORIES: DB_CATEGORIES,
  DB_COMPANY: DB_COMPANY,
  DB_CUSTOMERS: DB_CUSTOMERS,
  DB_DELIVERY_SERVICES: DB_DELIVERY_SERVICES,
  DB_DEVICES: DB_DEVICES,
  DB_NOTIFICATIONS: DB_NOTIFICATIONS,
  DB_SETTINGS: DB_SETTINGS,
  DB_STATISTICS: DB_STATISTICS,
  DB_TEMPLATES: DB_TEMPLATES,
  DB_TOURS: DB_TOURS,
  DB_USERS: DB_USERS,
  DB_VEHICLES: DB_VEHICLES,
  DB_MGMT_ACCOUNTS: DB_MGMT_ACCOUNTS,
  DB_MGMT_SENSORS: DB_MGMT_SENSORS,
  DB_MGMT_USERS: DB_MGMT_USERS,
  ST_ADDITIONALS: ST_ADDITIONALS,
  ST_ARCHIVES: ST_ARCHIVES,
  ST_PROFILE_IMAGES: ST_PROFILE_IMAGES,
  ST_SIGNS: ST_SIGNS,
  ST_USERS: ST_USERS,
  ST_VEHICLES: ST_VEHICLES
}
