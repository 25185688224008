import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/plugins/firebase'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { vuescroll, vuescrollconfig } from '@/plugins/vuescroll'
import { i18n } from '@/plugins/localization'
import ThemeStore from '@/store/modules/themeStore'
import LocalizationStore from '@/store/modules/LocalizationStore'
import SidebarStore from '@/store/modules/SidebarStore'
import { VueMaskDirective } from 'v-mask'

// Bootstrap scss import
import '@/assets/sass/default.scss'
// Bootstrap
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// v-mask
Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

// Vuescroll
Vue.use(vuescroll, { ops: {} })
Vue.prototype.$vuescrollConfig = vuescrollconfig

new Vue({
  i18n,
  router,
  store,
  beforeCreate () {
    ThemeStore.initializeTheme()
    LocalizationStore.initializeLocale()
    SidebarStore.initializeSidebar()
  },
  render: h => h(App)
}).$mount('#app')
