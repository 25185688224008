














import { Component, Vue } from 'vue-property-decorator'
import PrivacyContentVueComponent from '@/components/Legal/PrivacyContent.vue'

@Component({
  components: { PrivacyContentVueComponent }
})
export default class PrivacyVueComponent extends Vue {
  get isAdminArea (): boolean {
    return this.$router.currentRoute.name === 'privacyAdmin'
  }

  get redirectComponentName (): string {
    return typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : 'signin'
  }
}
