































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import SetupStore from '@/store/modules/SetupStore'
import { authenticationStore } from '@/store/modules/authenticationStore'
import { AuthenticationInstance } from '@/data/authentication'

@Component
export default class AppComponent extends Vue {
  /**
   * Listen on the `isInitialized` property of the `SetupStore`.
   */
  get getSetupIsInitialized (): boolean {
    return SetupStore.initialized
  }

  /**
   * Show an overlay messaging, that the app is currenlty loading. The overlay
   * should disappear transitioned when the related app is connected and the
   * user property on the `authenticationStore` ist set.
   */
  get showLoadingOverlay (): boolean {
    if (authenticationStore.relatedConnected && this.getSetupIsInitialized) {
      return authenticationStore.relatedUser === null
    }
    return false
  }

  /**
   * Show an alert displaying a message saying that there is no internet
   * connection based on the setup state object.
   */
  get showLostConnectionAlert (): boolean {
    return this.getSetupIsInitialized ? !SetupStore.connected : false
  }

  mounted (): void {
    AuthenticationInstance.getInstance().listenOnDefaultAuthState()
  }

  @Watch('$route', { deep: true, immediate: true })
  onRouteNameChanged (to: Route): void {
    const locale = this.$i18n.locale
    if (to.meta?.title) {
      if (locale === 'de') {
        document.title = to.meta?.title.de + ' | emuwa Logistik' || 'emuwa Logistik'
      } else {
        document.title = to.meta?.title.en + ' | emuwa logistics' || 'emuwa logistics'
      }
    } else {
      document.title = locale === 'de' ? 'emuwa Logistik' : 'emuwa logistics'
    }
  }
}
