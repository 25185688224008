import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '..'
import { User } from 'firebase/auth'

export interface AuthenticationUser {
  uid: string
  email: string | null
  displayName: string | null
  phoneNumber: string | null
  photoURL: string | null
  isAnonymous: boolean
  emailVerified: boolean
}

export interface AdministratorUser {
  key: string | null
  isAdministrator: boolean
}

export interface RelatedUser {
  confirmed: boolean | null
  created: number | null
  email: string | null
  firstname: string | null
  lastname: string | null
  imageSrc: string | null
  phone: string | null
  role: string | null
}

@Module
export class AuthenticationStore extends VuexModule {
  public user: AuthenticationUser | null = null
  public relatedUserData: RelatedUser | null = null
  public administratorUsersData: Array<AdministratorUser> | null = null
  public isSigningUp = false
  public isSigningIn = false
  public isRelatedConnected = false
  public isInitialized = false

  get initialized (): boolean {
    return this.isInitialized
  }

  get signingIn (): boolean {
    return this.isSigningIn
  }

  get relatedConnected (): boolean {
    return this.isRelatedConnected
  }

  get getUser (): AuthenticationUser | undefined {
    return this.user ?? undefined
  }

  get userAuth (): boolean {
    return !!this.user
  }

  get relatedUser (): RelatedUser | null {
    return this.relatedUserData
  }

  get administratorUsers (): Array<AdministratorUser> | null {
    return this.administratorUsersData
  }

  get isNoAdministratorExisiting (): boolean {
    if (this.administratorUsersData && this.administratorUsersData.length > 0) {
      return false
    }
    return true
  }

  get isAdministrator (): boolean | null {
    if (this.administratorUsersData && this.administratorUsersData.length > 0 && this.getUser) {
      const index = this.administratorUsersData.map(user => user.key).indexOf(this.getUser.uid)
      if (index > -1) {
        const administrator = this.administratorUsersData[index]
        return administrator.isAdministrator
      }
      return false
    }
    return false
  }

  @Mutation authStateChanged (user: User | null): void {
    this.isInitialized = true
    this.user = user
  }

  @Mutation setSigningIn (isSigningIn: boolean): void {
    this.isSigningIn = isSigningIn
  }

  @Mutation setUser (user: User | null): void {
    if (user !== null) {
      this.user = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous
      }
    } else {
      this.user = null
    }
  }

  @Mutation setRelatedUser (relatedUser: RelatedUser | null): void {
    this.relatedUserData = relatedUser
  }

  @Mutation setAdministratorUsers (administratorUsers: Array<AdministratorUser> | null): void {
    this.administratorUsersData = administratorUsers
  }

  @Mutation setSigningUp (payload: boolean): void {
    this.isSigningUp = payload
  }

  @Mutation setRelatedConnected (payload: boolean): void {
    this.isRelatedConnected = payload
  }
}

export const authenticationStore = new AuthenticationStore({ store: store, name: 'authentication' })
