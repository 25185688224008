import constants from '@/common/constants'
import { getDatabase, ref, update, serverTimestamp, get } from 'firebase/database'
import { FirebaseAppInstance } from '@/data/app'
import CompanyStore from '@/store/modules/CompanyStore'
import { CompanyObject } from '..'

export class CompanyHandler {
  /**
   * Update single properties of the company.
   * @param data The comma-separated properties to be updated. `{'key': 'value'}`
   */
  // eslint-disable-next-line
  public static async updateCompanyProperties (data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const companyRef = ref(db, constants.DB_COMPANY)

    await update(companyRef, {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  /**
   * Load company data once. This will update the properties on the
   * `CompanyStore`.
   */
  public static async loadCompanyDataOnce (): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const companyRef = ref(db, constants.DB_COMPANY)

    await get(companyRef).then((snapshot) => {
      if (snapshot.exists()) {
        const company = snapshot.val() as CompanyObject | null
        CompanyStore.setCompany(company)
      } else {
        CompanyStore.setCompany(null)
      }
    })
    Promise.resolve()
  }
}
