/**
 * Interface for defining a localization language.
 */
export interface Localization {
  locale: string
}

/**
 * Options to decide whether to use language codes only.
 */
export interface LocaleOptions {
  languageCodeOnly: boolean
}

/**
 * Enum to define, which languages are supported by the app.
 */
export enum Locales {
  DE = 'de',
  EN = 'en'
}

export const LOCALES = [
  { value: Locales.DE, caption: 'Deutsch' },
  { value: Locales.EN, caption: 'English' }
]
