







import { Component, Vue } from 'vue-property-decorator'
import SubHeader from '@/components/Sidebar/SubHeader.vue'

@Component({
  components: { SubHeader }
})
export default class DashboardContentVueComponent extends Vue {}
