



















































































import { Component, Vue } from 'vue-property-decorator'
import { authenticationStore, AuthenticationUser } from '@/store/modules/authenticationStore'
import LocalizationStore from '@/store/modules/LocalizationStore'
import { AuthenticationInstance } from '@/data/authentication'
import SidebarItems from './Sidebar/SidebarItems.vue'

@Component({
  components: { SidebarItems }
})
export default class Header extends Vue {
  public logoProps = { height: 40, width: 40 }
  public countryIconProps = { height: 24, width: 24 }

  get isUserAuth (): boolean {
    return authenticationStore.userAuth
  }

  get user (): AuthenticationUser | null {
    return authenticationStore.user
  }

  get profileImage (): string | null {
    return authenticationStore.relatedUserData?.imageSrc ?? null
  }

  get initials (): string | null {
    const first = authenticationStore.relatedUserData?.firstname?.charAt(0)
    const last = authenticationStore.relatedUserData?.lastname?.charAt(0)
    return `${first}${last}`
  }

  /**
   * Switch the global app locale.
   */
  public switchLocale (localeKey: string): void {
    LocalizationStore.changeLocale(localeKey)
  }

  /**
   * Sign out the currently signed in user.
   */
  public signOut (): void {
    AuthenticationInstance.getInstance().signOutFromApp()
  }

  // /**
  //  * Check if the given name is currently active.
  //  * @param itemName The router name.
  //  * @returns `true` or `false`
  //  */
  // public isActive (itemName: string): boolean {
  //   return this.activeItem === itemName
  // }

  // /**
  //  * Set the navigation item with the specified name to be active.
  //  * @param itemName The router name.
  //  */
  // public setActive (itemName: string): void {
  //   this.activeItem = itemName
  // }
}
