








import { Component, Vue } from 'vue-property-decorator'
import AuthenticationContent from './AuthenticationContent.vue'

@Component({
  components: {
    AuthenticationContent
  }
})
export default class AuthenticationLayoutVueComponent extends Vue {}
