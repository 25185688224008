




























































































import { Component, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { ResponseError } from '@/data/error'
import { AuthenticationInstance } from '@/data/authentication'

@Component
export default class ChangePasswordForm extends Vue {
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public password = ''
  public passwordOld = ''
  public passwordConfirm = ''

  get statePassword (): false | null {
    return this.formCheck.findFormError('password') ? false : null
  }

  get statePasswordConfirm (): false | null {
    return this.formCheck.findFormError('passwordConfirm') ? false : null
  }

  get statePasswordOld (): false | null {
    return this.formCheck.findFormError('passwordOld') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitChangePassword (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      const auth = getAuth()
      const user = auth.currentUser
      if (user !== null) {
        if (user.email) {
          const credential = EmailAuthProvider.credential(user.email, this.passwordOld)
          reauthenticateWithCredential(user, credential).then(() => {
            updatePassword(user, this.password).then(() => {
              this.resetForm()
              this.$root.$bvToast.toast(this.$tc('authResults.changePassword.success'), {
                title: this.$tc('authResults.changePassword.title'),
                autoHideDelay: 15000,
                appendToast: true,
                solid: true,
                variant: 'success'
              })
              AuthenticationInstance.getInstance().signOutFromApp()
            }).catch((error) => {
              if (error.code) {
                error.name = error.code
              }
              const resError = new ResponseError(error.name, error.message)
              this.responseError = resError.getErrorLocaleKey()
              this.showResponseAlert = true
            })
          }).catch((error) => {
            if (error.code) {
              error.name = error.code
            }
            const resError = new ResponseError(error.name, error.message)
            this.responseError = resError.getErrorLocaleKey()
            this.showResponseAlert = true
          })
        }
      }
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('password', this.password)
    this.formCheck.checkLengthGreater('password', this.password, 6)
    this.formCheck.checkTextRequired('passwordConfirm', this.passwordConfirm)
    this.formCheck.checkTextRequired('passwordOld', this.passwordOld)
    this.formCheck.checkInputEquals('passwordConfirm', this.passwordConfirm, this.password)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.password = ''
    this.passwordConfirm = ''
    this.passwordOld = ''
    this.formCheck.clearFormErrors()
    this.showResponseAlert = false
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
