import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '../'
import { Locale } from '@/localization'
import { i18n } from '@/plugins/localization'
import { LocaleStorage } from '@/plugins/StorageHelper'
import moment from 'moment'

@Module()
class LocalizationStore extends VuexModule {
  public appLocale = 'de'

  /**
   * get the currently used app localization
   * @returns Localization identifier
   */
  get locale (): string {
    return this.appLocale
  }

  /**
   * Toggle sidebar collapsed state.
   *
   * This method triggers the mutation to toggle the sidebars collapsed state.
   */
  @Action changeLocale (locale: string): void {
    this.setLocale(locale)
  }

  /**
   * Set the initial Localization state.
   */
  @Action initializeLocale (): void {
    const localeStorage = new LocaleStorage()
    const localeConfig = localeStorage.config
    if (localeConfig.locale !== null) {
      this.setLocale(localeConfig.locale)
    } else {
      const browserLocale = Locale.getLocaleByBrowser({ languageCodeOnly: true })
      this.setLocale(browserLocale)
    }
  }

  /**
   * Set a new app localization
   * @param locale The new localization state
   */
  @Mutation setLocale (locale: string): void {
    this.appLocale = locale
    i18n.locale = locale
    moment.locale(locale)
    const localeStorage = new LocaleStorage()
    localeStorage.addLocale(this.appLocale)
  }
}

export default new LocalizationStore({ store: store, name: 'localization' })
