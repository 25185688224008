import VueScroll from 'vuescroll'

export const vuescroll = VueScroll

/**
 * Important Note:
 *
 * using `sizeStrategy: 'percent'` results in the best usage
 * The problem here is, that `detectResize` will not work with
 * percent together, so you need to use `number`. But when resizing
 * the window, the scrollbar will not be updated! But using `percent`
 * will show the native scrollbar on windows machines.
 */
export const vuescrollconfig = {
  vuescroll: {
    mode: 'native', // native | slide
    sizeStrategy: 'number', // number | percent
    detectResize: true,
    locking: true
  },
  rail: {
    background: '#5a6268',
    opacity: 1,
    gutterOfSide: '0',
    specifyBorderRadius: true
  },
  bar: {
    background: '#75b900',
    onlyShowBarOnScroll: false
  },
  scrollButton: {
    enable: false
  }
}
