import { ResponseError } from '@/data/error'
import { FirebaseApp } from 'firebase/app'
import { sendPasswordResetEmail, getAuth } from 'firebase/auth'

export interface ForgotPasswordObject {
  email: string
}

export class ForgotPassword implements ForgotPasswordObject {
  public email: string

  constructor (email: string) {
    this.email = email
  }

  /**
   * Send a password reset email to the given email address.
   */
  public async sendPasswordResetEmail (app?: FirebaseApp): Promise<void> {
    const auth = getAuth(app)
    const result = await sendPasswordResetEmail(auth, this.email)
      .catch((error) => {
        return error
      })

    return new Promise((resolve, reject) => {
      if (result) {
        reject(new ResponseError(result.code, result.message))
      }
      resolve()
    })
  }
}
