













































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { ProfileHandler } from '@/data/profile'
import { ResponseError } from '@/data/error'

@Component
export default class EditProfileForm extends Vue {
  @Prop() uid!: string
  @Prop() email!: string
  @Prop() firstname!: string
  @Prop() lastname!: string
  @Prop() phone!: string

  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public formEmail = this.email ?? ''
  public formFirstname = this.firstname ?? ''
  public formLastname = this.lastname ?? ''
  public formPhone = this.phone ?? ''

  get stateFirstname (): false | null {
    return this.formCheck.findFormError('firstname') ? false : null
  }

  get stateLastname (): false | null {
    return this.formCheck.findFormError('lastname') ? false : null
  }

  get statePhone (): false | null {
    return this.formCheck.findFormError('phone') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitEditProfile (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      ProfileHandler.updateUserProperties(this.uid, {
        firstname: this.formFirstname,
        lastname: this.formLastname,
        phone: this.formPhone
      }).then(() => {
        return ProfileHandler.updateCurrentUser(`${this.formFirstname} ${this.formLastname}`)
      }).then(() => {
        this.cancelForm()
        // this.$emit('cancelled-edit-profile-form')
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        this.responseError = responseError.getDatabaseErrorLocaleKey()
        this.showResponseAlert = true
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('firstname', this.formFirstname)
    this.formCheck.checkTextRequired('lastname', this.formLastname)
    this.formCheck.checkTextRequired('phone', this.formPhone)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.formFirstname = this.firstname
    this.formLastname = this.lastname
    this.formPhone = this.phone
    this.formCheck.clearFormErrors()
    this.showResponseAlert = false
  }

  /**
   * Cancel form. This method will emit the `cancelled-edit-profile-form`-event.
   */
  @Emit('cancelled-edit-profile-form')
  public cancelForm (): void {
    this.resetForm()
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
