import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '../'
import { SidebarStorage } from '@/plugins/StorageHelper'

@Module()
class SidebarStore extends VuexModule {
  public isCollapsed = false

  /**
   * Get the current state value of the `isCollapsed` property.
   * @returns `true`, if sidebar is set to be collapsed, `false` otherwise.
   */
  get collapsed (): boolean {
    return this.isCollapsed
  }

  /**
   * Toggle sidebar collapsed state.
   *
   * This method triggers the mutation to toggle the sidebars collapsed state.
   */
  @Action toggleCollapsed (): void {
    this.setCollapsed(!this.isCollapsed)
  }

  /**
   * Initialize the sidebar state.
   *
   * This methods first gets the stored property from localStorage, if exists.
   * Based on this storedProperty it mutates the `isCollapsed` state to the
   * related value.
   */
  @Action initializeSidebar (): void {
    const sidebarStorage = new SidebarStorage()
    const sidebarConfig = sidebarStorage.config
    this.setCollapsed(sidebarConfig.collapsed)
  }

  /**
   * Set the sidebar collapsed state.
   *
   * This methods mutates the `isCollapsed` state to the related value.
   * Furthermore this related value will also be written to the localStorage.
   * @param collapse The new `isCollapsed` value.
   */
  @Mutation setCollapsed (collapse: boolean): void {
    this.isCollapsed = collapse
    const sidebarStorage = new SidebarStorage()
    sidebarStorage.addSidebarCollapsed(this.isCollapsed)
  }
}

export default new SidebarStore({ store: store, name: 'sidebar' })
