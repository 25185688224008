





























import { Component, Vue } from 'vue-property-decorator'
import CardLoadingOverlay from '@/components/overlays/CardLoadingOverlay.vue'
import LocalizationDropdown from '@/components/Localization/LocalizationDropdown.vue'
import SignUpForm from '@/components/forms/SignUpForm.vue'

@Component({
  components: { CardLoadingOverlay, LocalizationDropdown, SignUpForm }
})
export default class SignupVueComponent extends Vue {
  public isLoading = false

  public onSignUpLoadingChanged (isLoading: boolean): void {
    this.isLoading = isLoading
  }
}
