
type AuthenticationErrorType = {
  [index: string]: string
}

const params: AuthenticationErrorType = {
  'admin-restricted-operation': 'authenticationError.adminRestrictedOperation',
  'argument-error': 'authenticationError.argumentError',
  'app-not-authorized': 'authenticationError.appNotAuthorized',
  'app-not-installed': 'authenticationError.appNotInstalled',
  'no-app': 'authenticationError.noApp',
  'captcha-check-failed': 'authenticationError.captchaCheckFailed',
  'code-expired': 'authenticationError.codeExpired',
  'cordova-not-ready': 'authenticationError.cordovaNotReady',
  'cors-unsupported': 'authenticationError.corsUnsupported',
  'credential-already-in-use': 'authenticationError.credentialAlreadyInUse',
  'credential-token-mismatch': 'authenticationError.credentialTokenMismatch',
  'requires-recent-login': 'authenticationError.requiresRecentLogin',
  'dynamic-link-not-activated': 'authenticationError.dynamicLinkNotActivated',
  'email-change-needs-verification': 'authenticationError.emailChangeNeedsVerification',
  'email-already-in-use': 'authenticationError.emailAlreadyInUse',
  'emulator-config-failed': 'authenticationError.emulatorConfigFailed',
  'expired-action-code': 'authenticationError.expiredActionCode',
  'cancelled-popup-request': 'authenticationError.cancelledPopupRequest',
  'internal-error': 'authenticationError.internalError',
  'invalid-api-key': 'authenticationError.invalidApiKey',
  'invalid-app-credentials': 'authenticationError.invalidAppCredentials',
  'invalid-app-id': 'authenticationError.invalidAppId',
  'invalid-user-token': 'authenticationError.invalidUserToken',
  'invalid-auth-event': 'authenticationError.invalidAuthEvent',
  'invalid-cert-hash': 'authenticationError.invalidCertHash',
  'invalid-verification-code': 'authenticationError.invalidVerificationCode',
  'invalid-continue-uri': 'authenticationError.invalidContinueUri',
  'invalid-cordova-configuration': 'authenticationError.invalidCordovaConfiguration',
  'invalid-custom-token': 'authenticationError.invalidCustomToken',
  'invalid-dynamic-link-domain': 'authenticationError.invalidDynamicLinkDomain',
  'invalid-email': 'authenticationError.invalidEmail',
  'invalid-credential': 'authenticationError.invalidCredential',
  'invalid-message-payload': 'authenticationError.invalidMessagePayload',
  'invalid-multi-factor-session': 'authenticationError.invalidMultiFactorSession',
  'invalid-oauth-client-id': 'authenticationError.invalidOAuthClientId',
  'invalid-oauth-provider': 'authenticationError.invalidOAuthProvider',
  'invalid-action-code': 'authenticationError.invalidActionCode',
  'unauthorized-domain': 'authenticationError.unauthorizedDomain',
  'wrong-password': 'authenticationError.wrongPassword',
  'invalid-persistence-type': 'authenticationError.invalidPersistenceType',
  'invalid-phone-number': 'authenticationError.invalidPhoneNumber',
  'invalid-provider-id': 'authenticationError.invalidProviderId',
  'invalid-recipient-email': 'authenticationError.invalidRecipientEmail',
  'invalid-sender': 'authenticationError.invalidSender',
  'invalid-verification-id': 'authenticationError.invalidVerificationId',
  'invalid-tenant-id': 'authenticationError.invalidTenantId',
  'multi-factor-info-not-found': 'authenticationError.multiFactorInfoNotFound',
  'multi-factor-auth-required': 'authenticationError.multiFactorAuthRequired',
  'missing-android-pkg-name': 'authenticationError.missingAndroidPkgName',
  'missing-app-credential': 'authenticationError.missingAppCredential',
  'auth-domain-config-required': 'authenticationError.authDomainConfigRequired',
  'missing-verification-code': 'authenticationError.missingVerificationCode',
  'missing-continue-uri': 'authenticationError.missingContinueUri',
  'missing-iframe-start': 'authenticationError.missingIframeStart',
  'missing-ios-bundle-id': 'authenticationError.missingIosBundleId',
  'missing-or-invalid-nonce': 'authenticationError.missingOrInvalidNonce',
  'missing-multi-factor-info': 'authenticationError.missingMultiFactorInfo',
  'missing-multi-factor-session': 'authenticationError.missingMultifactorSession',
  'missing-phone-number': 'authenticationError.missingPhoneNumber',
  'missing-verification-id': 'authenticationError.missingVerificationId',
  'app-deleted': 'authenticationError.appDeleted',
  'account-exists-with-different-credential': 'authenticationError.accountExistsWithDifferentCredential',
  'network-request-failed': 'authenticationError.networkRequestFailed',
  'null-user': 'authenticationError.nullUser',
  'no-auth-event': 'authenticationError.noAuthEvent',
  'no-such-provider': 'authenticationError.noSuchProvider',
  'operation-not-allowed': 'authenticationError.operationNotAllowed',
  'operation-not-supported-in-this-environment': 'authenticationError.operationNotSupportedInThisEnvironment',
  'popup-blocked': 'authenticationError.popupBlocked',
  'popup-closed-by-user': 'authenticationError.popupClosedByUser',
  'provider-already-linked': 'authenticationError.providerAlreadyLinked',
  'quota-exceeded': 'authenticationError.quotaExceeded',
  'redirect-cancelled-by-user': 'authenticationError.redirectCancelledByUser',
  'redirect-operation-pending': 'authenticationError.redirectOperationPending',
  'rejected-credential': 'authenticationError.rejectedCredential',
  'second-factor-already-in-use': 'authenticationError.secondFactorAlreadyInUse',
  'maximum-second-factor-count-exceeded': 'authenticationError.maximumSecondFactorCountExceeded',
  'tenant-id-mismatch': 'authenticationError.tenantIdMismatch',
  timeout: 'authenticationError.timeout',
  'user-token-expired': 'authenticationError.userTokenExpired',
  'too-many-requests': 'authenticationError.tooManyRequests',
  'unauthorized-continue-uri': 'authenticationError.unauthorizedContinueUri',
  'unsupported-first-factor': 'authenticationError.unsupportedFirstFactor',
  'unsupported-persistence-type': 'authenticationError.unsupportedPersistenceType',
  'unsupported-tenant-operation': 'authenticationError.unsupportedTenantOperation',
  'unverified-email': 'authenticationError.unverifiedEmail',
  'user-cancelled': 'authenticationError.userCancelled',
  'user-not-found': 'authenticationError.userNotFound',
  'user-disabled': 'authenticationError.userDisabled',
  'user-mismatch': 'authenticationError.userMismatch',
  'user-signed-out': 'authenticationError.userSignedOut',
  'weak-password': 'authenticationError.weakPassword',
  'web-storage-unsupported': 'authenticationError.webStorageUnsupported',
  'unexpected-error': 'authenticationError.unexpectedError',
  'validation-key-not-found': 'authenticationError.validationKeyNotFound',
  'email-not-verified': 'authenticationError.emailNotVerified',
  'no-default-signup-user': 'authenticationError.noDefaultSignupUser',
  'no-related-signup-user': 'authenticationError.noRelatedSignupUser',
  'no-role': 'authenticationError.noRole',
  'admin-pending': 'authenticationError.adminPending',
  'driver-account': 'authenticationError.driverAccount'
}

export default class FirebaseAuthenticationErrors {
  static authenticationErrorLocaleKeyByCode (code: string): string | undefined {
    return params[code]
  }
}
