import { AddCategoryFormObject, CategoryObject } from './category'
import constants from '@/common/constants'
import {
  getDatabase, ref, child,
  push, update, remove,
  serverTimestamp, increment
} from 'firebase/database'
import { FirebaseAppInstance } from '@/data/app'
import { CategoryObjectForAddCustomer } from '..'

/**
 * Object interface to create a new category in the firebase database.
 */
interface DbAddCategory extends AddCategoryFormObject {
  // eslint-disable-next-line
  created: any
}

/**
 * Handling the categories.
 */
export class CategoriesHandler {
  /**
   * Create a new category by using the `AddCategoryForm`.
   * @param category The `Category`-Form object.
   * @returns Promise
   */
  public static async createCategory (category: AddCategoryFormObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const categoryRef = ref(db)
    const newPostKey = push(child(categoryRef, constants.DB_CATEGORIES)).key

    const newCategory: DbAddCategory = {
      name: category.name,
      color: category.color,
      created: serverTimestamp()
    }

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates[constants.DB_CATEGORIES + '/' + newPostKey] = newCategory
    updates[constants.DB_STATISTICS + '/' + constants.DB_CATEGORIES + '/count'] = increment(1)

    await update(categoryRef, updates)
    return Promise.resolve()
  }

  /**
   * Update custom properties of a category.
   *
   * This will also add or update the `modified` property.
   * @param key The database key.
   * @param data The data dictionary-object containing all changes.
   * @returns Promise
   */
  // eslint-disable-next-line
  public static async updateCategoryProperties (category: CategoryObject, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const categoryRef = ref(db, constants.DB_CATEGORIES)
    const customersRef = ref(db, constants.DB_CUSTOMERS)

    const c: CategoryObjectForAddCustomer = {
      ...data as CategoryObjectForAddCustomer
    }
    // eslint-disable-next-line
    const customerUpdates: Record<string, any> = {}
    category.customers.forEach(customer => {
      customerUpdates[customer.key + '/' + constants.DB_CATEGORIES + '/' + category.key + '/name'] = c.name
      customerUpdates[customer.key + '/' + constants.DB_CATEGORIES + '/' + category.key + '/color'] = c.color
    })
    await update(customersRef, customerUpdates)

    await update(child(categoryRef, category.key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  /**
   * Remove a category.
   * @param category The category that should be removed.
   */
  public static async removeCategory (category: CategoryObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const categoryRef = ref(db, constants.DB_CATEGORIES)
    const customersRef = ref(db, constants.DB_CUSTOMERS)

    // eslint-disable-next-line
    const customerUpdates: Record<string, any> = {}
    category.customers.forEach(customer => {
      customerUpdates[customer.key + '/' + constants.DB_CATEGORIES + '/' + category.key] = null
    })
    await update(customersRef, customerUpdates)

    await remove(child(categoryRef, category.key))
    Promise.resolve()
  }
}
