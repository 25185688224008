





























































import { Component, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { ResponseError } from '@/data/error'
import { AddCategoryFormObject, CategoriesHandler } from '@/data/category'

@Component
export default class AddCategoryForm extends Vue {
  private readonly defaultColor = '#4ca3dd'

  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public category: AddCategoryFormObject = {
    name: '',
    color: this.defaultColor
  }

  get stateName (): false | null {
    return this.formCheck.findFormError('categoryName') ? false : null
  }

  get stateColor (): false | null {
    return this.formCheck.findFormError('color') ? false : null
  }

  /**
   * Submit the add category form.
   */
  public submitAddCategory (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      CategoriesHandler.createCategory(this.category).then(() => {
        this.resetForm()
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('categoryName', this.category.name)
    this.formCheck.checkLengthGreater('categoryName', this.category.name, 2)
    this.formCheck.checkLengthLessThan('categoryName', this.category.name, 24)
    return this.formCheck.hasErrors()
  }

  /**
   * Trim category name to remove whitespaces.
   */
  public categoryNameFormatter (value: string): string {
    return value.trim()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.category = {
      name: '',
      color: this.defaultColor
    }
    this.formCheck.clearFormErrors()
  }
}
