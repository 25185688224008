














import { Component, Vue } from 'vue-property-decorator'
import ImprintContentVueComponent from '@/components/Legal/ImprintContent.vue'

@Component({
  components: { ImprintContentVueComponent }
})
export default class ImprintVueComponent extends Vue {
  get isAdminArea (): boolean {
    return this.$router.currentRoute.name === 'imprintAdmin'
  }

  get redirectComponentName (): string {
    return typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : 'signin'
  }
}
