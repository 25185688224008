
export default class FormCheck {
  /**
   * This property holds all errors.
   */
  public formErrors: Error[]

  /**
   * This class checks form inputs based on special criterias.
   *
   * The found errors will be stored in the `formErrors` property.
   */
  constructor () {
    this.formErrors = []
  }

  /**
   * Clear the `formErrors` property.
   */
  public clearFormErrors (): void {
    this.formErrors = []
  }

  /**
   * Check the input for a required value.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkTextRequired (
    inputName: string,
    inputValue: string
  ): void {
    if (inputValue.length <= 0) {
      this.formErrors.push(this.generateLocaleError(inputName, 'required'))
    }
  }

  /**
   * Check the input for a required file value.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkFileRequired (
    inputName: string,
    inputValue: File | null
  ): void {
    if (!inputValue) {
      this.formErrors.push(this.generateLocaleError(inputName, 'required'))
    }
  }

  /**
   * Check the input for a email formatted value.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkEmailFormat (
    inputName: string,
    inputValue: string
  ): void {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regexp.test(inputValue)) {
      this.formErrors.push(this.generateLocaleError(inputName, 'format'))
    }
  }

  /**
   * Check the input for zip code formatted value.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkZipCodeFormat (
    inputName: string,
    inputValue: string
  ): void {
    const numberRegexp = /^[0-9]+$/
    if (!(inputValue.length === 5 && numberRegexp.test(inputValue))) {
      this.formErrors.push(this.generateLocaleError(inputName, 'format'))
    }
  }

  /**
   * Check the input for a given value length.
   * @param inputName The input name.
   * @param inputValue The input value.
   * @param expectedLength The expected value length.
   */
  public checkLengthEqual (
    inputName: string,
    inputValue: string,
    expectedLength: number
  ): void {
    if (inputValue.length !== expectedLength) {
      this.formErrors.push(this.generateLocaleError(inputName, 'length'))
    }
  }

  /**
   * Check the input for being able to convert the input to a number.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkIsNumber (
    inputName: string,
    inputValue: string
  ): void {
    const regexp = /^-?[\d.]+(?:e-?\d+)?$/
    if (!regexp.test(inputValue)) {
      this.formErrors.push(this.generateLocaleError(inputName, 'format'))
    }
  }

  /**
   * Check the input for a value being greater than a given length number.
   * @param inputName The input name.
   * @param inputValue The input value.
   * @param minLength The given minimum value length.
   */
  public checkLengthGreater (
    inputName: string,
    inputValue: string,
    minLength: number
  ): void {
    if (inputValue.length < minLength) {
      this.formErrors.push(this.generateLocaleError(inputName, 'min'))
    }
  }

  /**
   * Check the input for a value being less than a given length number.
   * @param inputName The input name.
   * @param inputValue The input value.
   * @param maxLength The given maximum value length.
   */
  public checkLengthLessThan (
    inputName: string,
    inputValue: string,
    maxLength: number
  ): void {
    if (inputValue.length > maxLength) {
      this.formErrors.push(this.generateLocaleError(inputName, 'max'))
    }
  }

  /**
   * Check the input for having an equal value as a given value.
   * @param inputName The input name.
   * @param inputValue The input value.
   * @param equalValue the given value to be equal to.
   */
  public checkInputEquals (
    inputName: string,
    inputValue: string,
    equalValue: string
  ): void {
    if (inputValue !== equalValue) {
      this.formErrors.push(this.generateLocaleError(inputName, 'equal'))
    }
  }

  /**
   * Check the input for a checked checkbox value.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkCheckboxChecked (
    inputName: string,
    inputValue: boolean
  ): void {
    if (!inputValue) {
      this.formErrors.push(this.generateLocaleError(inputName, 'required'))
    }
  }

  /**
   * Check the input for a valid german plate number format.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkPlateNumber (
    inputName: string,
    inputValue: string
  ): void {
    const regexp = new RegExp('^[A-Z]{1,3}[-][A-Z]{1,2}\\s[0-9]{1,4}$')
    if (!regexp.test(inputValue)) {
      this.formErrors.push(this.generateLocaleError(inputName, 'format'))
    }
  }

  /**
   * Check the input for a valid serial key number format.
   * @param inputName The input name.
   * @param inputValue The input value.
   */
  public checkSerialKey (
    inputName: string,
    inputValue: string
  ): void {
    const regexp = new RegExp('^[A-Z0-9]{4}[-][A-Z0-9]{4}[-][A-Z0-9]{4}[-][A-Z0-9]{4}$')
    if (!regexp.test(inputValue)) {
      this.formErrors.push(this.generateLocaleError(inputName, 'format'))
    }
  }

  /**
   * Determine whether the form check contains errors.
   * @returns true if errors exist, false otherwise.
   */
  public hasErrors (): boolean {
    return this.formErrors.length > 0
  }

  /**
   * Get the corresponding error.
   * @param inputName The input name.
   * @returns The corresponding `Error`.
   */
  public findFormError (inputName: string): Error | undefined {
    return this.formErrors.find((value: Error) => value.name === inputName)
  }

  /**
   * Get the corresponding error message.
   * @param inputName The input name.
   * @returns The error message.
   */
  public formErrorMessage (inputName: string): string {
    const error = this.findFormError(inputName)
    return error ? error.message : ''
  }

  /**
   * Generate the `Error` with localized values.
   * @param inputName The input name.
   * @param localeKey The corresponding error value based on the localization.
   * @returns The formatted `Error`.
   */
  private generateLocaleError (inputName: string, localeKey: string): Error {
    return { name: inputName, message: `form.${inputName}.error.${localeKey}` }
  }
}
