





























import { Component, Vue } from 'vue-property-decorator'
import CardLoadingOverlay from '@/components/overlays/CardLoadingOverlay.vue'
import LocalizationDropdown from '@/components/Localization/LocalizationDropdown.vue'
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm.vue'

@Component({
  components: { CardLoadingOverlay, LocalizationDropdown, ForgotPasswordForm }
})
export default class ForgotPasswordVueComponent extends Vue {
  public isLoading = false

  public onForgotPasswordLoadingChanged (isLoading: boolean): void {
    this.isLoading = isLoading
  }
}
