





































































import { Component, Vue } from 'vue-property-decorator'
import { authenticationStore } from '@/store/modules/authenticationStore'
import { Signin } from '@/data/authentication'
import { ResponseError } from '@/data/error'
import FormCheck from '@/common/formCheck'

@Component
export default class SignInForm extends Vue {
  public email = ''
  public password = ''
  public formCheck: FormCheck = new FormCheck()
  public responseError: string | null = null
  public showResponseAlert = false
  public isLoading = false

  get stateEmail (): false | null {
    return this.formCheck.findFormError('email') ? false : null
  }

  get statePassword (): false | null {
    return this.formCheck.findFormError('password') ? false : null
  }

  /**
   * Submit sign in form.
   *
   * This methods calls the `signIn` method, if all input values are valid.
   */
  public submitSigninForm (): void {
    authenticationStore.setSigningUp(false)
    if (!this.checkForm()) {
      this.signIn()
    }
  }

  /**
   * Sign in with the provided credentials.
   */
  public signIn (): void {
    this.setLoading(true)
    authenticationStore.setSigningIn(true)
    const signin = new Signin(this.email, this.password)
    signin.signin().then(() => {
      this.clearForm(true)
    }).catch((error: ResponseError) => {
      if (error.name.endsWith('email-not-verified')) {
      }
      this.responseError = error.getErrorLocaleKey()
      this.showResponseAlert = true
    }).finally(() => {
      this.setLoading(false)
      authenticationStore.setSigningIn(false)
    })
  }

  /**
   * Check the form input against rules before submitting
   */
  public checkForm (): boolean {
    this.clearForm()
    this.formCheck.checkTextRequired('email', this.email)
    this.formCheck.checkEmailFormat('email', this.email)
    this.formCheck.checkTextRequired('password', this.password)
    return this.formCheck.hasErrors()
  }

  /**
   * Clear all form properties.
   *
   * @param clearInputs Whether to clear inputs as well.
   */
  public clearForm (clearInputs = false): void {
    this.formCheck.clearFormErrors()
    this.responseError = null
    this.showResponseAlert = false

    if (clearInputs) {
      this.email = ''
      this.password = ''
    }
  }

  /**
   * Set a value for the `isLoading` property.
   *
   * This method will emit the `sign-in-is-loading`-event.
   */
  public setLoading (isLoading: boolean): void {
    this.isLoading = isLoading
    this.$emit('sign-in-is-loading', this.isLoading)
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
