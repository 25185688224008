import constants from '@/common/constants'
import { FirebaseOptions, FirebaseApp, getApps, deleteApp } from 'firebase/app'
import { getDatabase, ref, get, set, update, serverTimestamp } from 'firebase/database'
import { getAuth, User, updateProfile, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { ResponseError } from '@/data/error'
import { FirebaseAppInstance } from '@/data/app'

export interface SignupFormObject {
  validationKey: number
  email: string
  firstname: string
  lastname: string
  phone: string
  password: string
}

interface RelatedAccountObject {
  account: string
  company: string
  created: number
  database: string
  mapquestKey: string
  storage: string
}

export class Signup implements SignupFormObject {
  private readonly APP_SIGNUP: string = constants.APP_SIGNUP

  public validationKey: number
  public email: string
  public firstname: string
  public lastname: string
  public phone: string
  public password: string

  private relatedAccount: RelatedAccountObject | undefined
  private firebaseInstance: FirebaseAppInstance

  constructor (signupObject: SignupFormObject) {
    this.validationKey = signupObject.validationKey
    this.email = signupObject.email
    this.firstname = signupObject.firstname
    this.lastname = signupObject.lastname
    this.phone = signupObject.phone
    this.password = signupObject.password

    this.firebaseInstance = FirebaseAppInstance.getInstance()
    this.relatedAccount = undefined
    this.firebaseInstance.deleteAppNamed(this.APP_SIGNUP)
  }

  public getFullname (): string {
    return `${this.firstname} ${this.lastname}`.trim()
  }

  /**
   * Sign up the user using the given SignupObject.
   *
   * 1. Load related data based on validation key.
   * 2. Create firebase [SIGNUP] app.
   * 3. Sign up the user using [SIGNUP] app.
   * 4. Update user profile
   * 5. Write user entry into [RELATED] database.
   * 6. Sign in user into [DEFAULT] app.
   * 7. Write user entry into [DEFAULT] database.
   * 8. Sign out user from [DEFAULT] app.
   * 9. Sign out user form [SIGNUP] app.
   * 10. delete [SIGNUP] app instance.
   * @returns Promise
   */
  public async signup (): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadRelatedData(this.validationKey)
        .then((relatedAccount: RelatedAccountObject) => {
          this.relatedAccount = relatedAccount
          const firebaseSignupOptions = this.relatedFirebaseOptions(relatedAccount.database, relatedAccount.storage)
          return this.firebaseInstance.addFirebaseApp(firebaseSignupOptions, this.APP_SIGNUP)
        })
        .then((app: FirebaseApp) => {
          return this.signupUser(this.email, this.password, app)
        })
        .then((user: User) => {
          updateProfile(user, {
            displayName: this.getFullname()
          })
          const appSignup = this.getFirebaseAppInstance(this.APP_SIGNUP)
          return this.createSignupUser(user.uid, appSignup)
        })
        .then((uid: string) => {
          return this.createDefaultUser(uid)
        })
        .then(() => {
          const appSignup = this.getFirebaseAppInstance(this.APP_SIGNUP)
          return this.updateCompany(appSignup)
        })
        .then(() => {
          return this.signOutUserFromApp()
        })
        .then(() => {
          const appSignup = this.getFirebaseAppInstance(this.APP_SIGNUP)
          return this.signOutUserFromApp(appSignup)
        })
        .then(() => {
          this.deleteFirebaseAppInstance(this.APP_SIGNUP)
          resolve()
        })
        .catch((error) => {
          this.deleteFirebaseAppInstance(this.APP_SIGNUP)
          if (error.code) {
            error.name = error.code
          }
          reject(new ResponseError(error.name, error.message))
        })
    })
  }

  /**
   * Load related account data based on the given validation key.
   * @param validationKey The required validation key for company connection.
   * @returns The database values of the related account or ResponseError.
   */
  private async loadRelatedData (validationKey: number): Promise<RelatedAccountObject> {
    const db = getDatabase()
    const accountRef = ref(db, `accounts/${validationKey}`)
    const accountSnapshot = await get(accountRef).then((snapshot) => {
      return snapshot
    })

    if (accountSnapshot.exists() && accountSnapshot.key) {
      const account: RelatedAccountObject = {
        account: accountSnapshot.key,
        company: accountSnapshot.val().name,
        database: accountSnapshot.val().database,
        storage: accountSnapshot.val().storage,
        mapquestKey: accountSnapshot.val().mapquestKey,
        created: accountSnapshot.val().created
      }
      return Promise.resolve(account)
    }
    return Promise.reject(
      new ResponseError('auth/validation-key-not-found', 'The validation key could not be found.')
    )
  }

  /**
   * Signup the user using the given credentials.
   * @param email The user's email address.
   * @param password The users' chosen password.
   * @param app The firebase app instance to use. Default: [DEFAULT].
   * @returns The resulting `user.user` object.
   */
  private async signupUser (email: string, password: string, app?: FirebaseApp): Promise<User> {
    const auth = getAuth(app)
    const user = await createUserWithEmailAndPassword(auth, email, password)
    return new Promise((resolve, reject) => {
      if (user.user) {
        resolve(user.user)
      }
      reject(new ResponseError('auth/some-bad-error', 'The user.user property is not set.'))
    })
  }

  /**
   * Create a firebase database entity for the newly signed up user into the
   * given FirebaseApp.
   * @param uid The user id to be used as database key.
   * @param app The `FirebaseApp` instance associated with the database to use.
   */
  private async createSignupUser (uid: string, app?: FirebaseApp): Promise<string> {
    const db = getDatabase(app)
    const userRef = ref(db, `users/${uid}`)
    await update(userRef, {
      created: serverTimestamp(),
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone,
      role: 'pending-admin'
    })
    return Promise.resolve(uid)
  }

  /**
   * Create a firebase database entry for the newly signed up user into the
   * [DEFAULT] database.
   *
   * This method first signs in the user.
   * @param uid The user id used as database key.
   */
  private async createDefaultUser (uid: string): Promise<void> {
    const auth = getAuth()
    await signInWithEmailAndPassword(auth, this.email, this.password)

    const db = getDatabase()
    const userRef = ref(db, `users/${uid}`)
    await set(userRef, {
      account: this.relatedAccount?.account,
      database: this.relatedAccount?.database,
      storage: this.relatedAccount?.storage,
      mapquestKey: this.relatedAccount?.mapquestKey,
      created: serverTimestamp()
    })
  }

  private async updateCompany (app?: FirebaseApp): Promise<void> {
    const db = getDatabase(app)
    const companyRef = ref(db, `${constants.DB_COMPANY}`)

    const companySnapshot = await get(companyRef).then((snapshot) => {
      return snapshot
    })
    if (!companySnapshot.exists()) {
      await update(companyRef, {
        name: this.relatedAccount?.company,
        created: this.relatedAccount?.created
      })
    }
    return Promise.resolve()
  }

  /**
   * Signs out the user from the given app instance. Default: [DEFAULT].
   * @param app The corresponding firebase app to sign out the user from.
   */
  private async signOutUserFromApp (app?: FirebaseApp): Promise<void> {
    const auth = getAuth(app)
    await auth.signOut()
    return Promise.resolve()
  }

  /**
   * Deletes the firebase app instance named by the given name.
   * @param name The firebase app's name.
   */
  private async deleteFirebaseAppInstance (name: string): Promise<void> {
    const appNamed = this.getFirebaseAppInstance(name)
    if (appNamed !== undefined) {
      await deleteApp(appNamed)
    }
    return Promise.resolve()
  }

  /**
   * Returns the app instance named by the given name.
   * @param name The firebase app's name.
   * @returns The firebase app or undefined.
   */
  private getFirebaseAppInstance (name: string): FirebaseApp | undefined {
    return getApps().find(app => app.name === name)
  }

  /**
   * Generate the `FirebaseOptions` object based on default values and given
   * database and storage urls.
   * @param database The database url.
   * @param storage The storage url.
   * @returns The `FirebaseOptions` object.
   */
  private relatedFirebaseOptions (database: string, storage: string): FirebaseOptions {
    return {
      apiKey: process.env.VUE_APP_API_KEY as string,
      appId: process.env.VUE_APP_APP_ID as string,
      projectId: process.env.VUE_APP_PROJECT_ID as string,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: database,
      storageBucket: storage
    }
  }
}
