import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { Position } from 'vue-router/types/router'

// Layouts
import DashboardLayoutVueComponent from '@/views/layout/DashboardLayout.vue'
import AuthenticationLayoutVueComponent from '@/views/layout/AuthenticationLayout.vue'

// Authentication pages
import SigninVueComponent from '@/views/authentication/Signin.vue'
import SignupVueComponent from '@/views/authentication/Signup.vue'
import ForgotPasswordVueComponent from '@/views/authentication/ForgotPassword.vue'

// Error pages
import NotFoundVueComponent from '@/views/NotFound.vue'

// Legal pages
import ImprintVueComponent from '@/views/legal/Imprint.vue'
import PrivacyVueComponent from '@/views/legal/Privacy.vue'
import CreditsVueComponent from '@/views/legal/Credits.vue'

// Admin pages
import DashboardVueComponent from '@/views/admin/Dashboard.vue'
import ProfileVueComponent from '@/views/admin/Profile.vue'
import DriversVueComponent from '@/views/admin/Drivers.vue'
import ToursVueComponent from '@/views/admin/Tours.vue'
import TourPlannerVueComponent from '@/views/admin/TourPlanner.vue'
import CalendarVueComponent from '@/views/admin/Calendar.vue'
import SettingsVueComponent from '@/views/admin/Settings.vue'
import SupportVueComponent from '@/views/admin/Support.vue'
import ArchiveVueComponent from '@/views/admin/Archive.vue'

// Import functionality
import { getAuth } from 'firebase/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/admin/',
    component: DashboardLayoutVueComponent,
    children: [
      {
        path: '/admin/dashboard',
        name: 'dashboard',
        alias: ['/admin/', '/admin/startseite'],
        component: DashboardVueComponent,
        meta: {
          title: {
            de: 'Dashboard',
            en: 'Dashboard'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/profile',
        name: 'profile',
        alias: ['/admin/Profil'],
        component: ProfileVueComponent,
        meta: {
          title: {
            de: 'Profil',
            en: 'Profile'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/customers',
        name: 'customers',
        alias: ['/admin/kunden'],
        component: () => import(/* webpackChunkName: "customers" */ '../views/admin/Customers.vue'),
        meta: {
          title: {
            de: 'Kunden',
            en: 'Customers'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/fleet',
        name: 'fleet',
        alias: ['/admin/flotte', '/admin/fahrzeuge', '/admin/vehicles'],
        component: () => import(/* webpackChunkName: "fleet" */ '../views/admin/Fleet.vue'),
        meta: {
          title: {
            de: 'Fahrzeugflotte',
            en: 'Vehicle fleet'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/drivers',
        name: 'drivers',
        alias: ['/admin/fahrer'],
        component: DriversVueComponent,
        meta: {
          title: {
            de: 'Fahrer',
            en: 'Drivers'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/sensors',
        name: 'sensors',
        alias: ['/admin/sensoren'],
        component: () => import(/* webpackChunkName: "sensors" */ '../views/admin/Sensors.vue'),
        meta: {
          title: {
            de: 'Sensoren',
            en: 'Sensors'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/tours',
        name: 'tours',
        alias: ['/admin/touren', '/admin/routen', 'admin/routeplanner'],
        component: ToursVueComponent,
        meta: {
          title: {
            de: 'Touren',
            en: 'Tours'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/plan-tour',
        name: 'plan-tour',
        alias: ['/admin/routenplaner', '/admin/routenplanung', 'admin/routeplanner'],
        component: TourPlannerVueComponent,
        meta: {
          title: {
            de: 'Tour planen',
            en: 'Plan a tour'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/calendar',
        name: 'calendar',
        alias: ['/admin/kalendar'],
        component: CalendarVueComponent,
        meta: {
          title: {
            de: 'Kalendar',
            en: 'Calendar'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/settings',
        name: 'settings',
        alias: ['/admin/einstellungen'],
        component: SettingsVueComponent,
        meta: {
          title: {
            de: 'Einstellungen',
            en: 'Settings'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/support',
        name: 'support',
        component: SupportVueComponent,
        meta: {
          title: {
            de: 'Support',
            en: 'Support'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/archive',
        name: 'archive',
        alias: ['/admin/archiv'],
        component: ArchiveVueComponent,
        meta: {
          title: {
            de: 'Archiv',
            en: 'Archive'
          },
          requiresAuth: true
        }
      },
      {
        path: '/admin/imprint',
        name: 'imprintAdmin',
        component: ImprintVueComponent,
        meta: {
          title: {
            de: 'Impressum',
            en: 'Imprint'
          },
          guest: true
        }
      },
      {
        path: '/admin/privacy',
        name: 'privacyAdmin',
        component: PrivacyVueComponent,
        meta: {
          title: {
            de: 'Datenschutzerklärung',
            en: 'Privacy'
          },
          guest: true
        }
      },
      {
        path: '/admin/credits',
        name: 'creditsAdmin',
        component: CreditsVueComponent,
        meta: {
          title: {
            de: 'Bildnachweise',
            en: 'Credits'
          },
          guest: true
        }
      },
      {
        path: '/admin/*',
        name: 'notFoundAdmin',
        component: NotFoundVueComponent,
        meta: {
          title: {
            de: 'Seite nicht gefunden',
            en: 'Page not found'
          },
          guest: true
        }
      }
    ]
  },
  {
    path: '/authentication',
    redirect: '/authentication/',
    component: AuthenticationLayoutVueComponent,
    children: [
      {
        path: '/authentication/signin',
        name: 'signin',
        alias: ['/authentication/anmelden', '/authentication/login'],
        component: SigninVueComponent,
        meta: {
          title: {
            de: 'Anmelden',
            en: 'Sign in'
          },
          guest: true
        }
      },
      {
        path: '/authentication/signup',
        name: 'signup',
        alias: ['/authentication/registrieren', '/authentication/register'],
        component: SignupVueComponent,
        meta: {
          title: {
            de: 'Registrieren',
            en: 'Sign up'
          },
          guest: true
        }
      },
      {
        path: '/authentication/forgot-password',
        name: 'forgotPassword',
        alias: ['/authentication/passwort-vergessen'],
        component: ForgotPasswordVueComponent,
        meta: {
          title: {
            de: 'Passwort vergessen',
            en: 'Forgot password'
          },
          guest: true
        }
      }
    ]
  },
  {
    path: '/signin',
    redirect: '/authentication/signin'
  },
  {
    path: '/anmelden',
    redirect: '/authentication/anmelden'
  },
  {
    path: '/login',
    redirect: '/authentication/login'
  },
  {
    path: '/passwort-vergessen',
    redirect: '/authentication/passwort-vergessen'
  },
  {
    path: '/registrieren',
    redirect: '/authentication/registrieren'
  },
  {
    path: '/register',
    redirect: '/authentication/register'
  },
  {
    path: '/imprint',
    name: 'imprint',
    alias: ['/impressum'],
    component: ImprintVueComponent,
    meta: {
      title: {
        de: 'Impressum',
        en: 'Imprint'
      },
      guest: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    alias: ['/datenschutz'],
    component: PrivacyVueComponent,
    meta: {
      title: {
        de: 'Datenschutz',
        en: 'Privacy'
      },
      guest: true
    }
  },
  {
    path: '/credits',
    name: 'credits',
    alias: ['/Bildnachweise'],
    component: CreditsVueComponent,
    meta: {
      title: {
        de: 'Bildnachweise',
        en: 'Credits'
      },
      guest: true
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFoundVueComponent,
    meta: {
      title: {
        de: 'Seite nicht gefunden',
        en: 'Page not found'
      },
      guest: true
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to: Route, from: Route, savedPosition: void | Position) {
    if (savedPosition) {
      return {
        x: savedPosition.x,
        y: savedPosition.y,
        behavior: 'smooth'
      }
    }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to: Route, from: Route, next) => {
  // Check if the requested page requires authentication and redirect
  // to `/signin` if user not signed in
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const auth = getAuth()
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) {
    next({
      name: 'signin',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
