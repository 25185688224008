





























import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AuthenticationContentVueComponent extends Vue {
  public logoProps = { height: 30, width: 30 }
  public redirectName = 'signin'

  @Watch('$route.name', { deep: true, immediate: true })
  onRouteNameChanged (name: string): void {
    this.redirectName = name
  }
}
