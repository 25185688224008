
























































































































































































import { Component, Vue } from 'vue-property-decorator'
import draggable, { MoveEvent } from 'vuedraggable'
// import draggable, { DraggedContext, MoveEvent } from 'vuedraggable'

interface routeItem {
  id: number
  orderNumber: number
  name: string
  type: string
  fixed: boolean
  selected?: boolean
  isTime?: boolean
}

@Component({
  components: { draggable }
})
export default class TourPlannerVueComponent extends Vue {
  public drag = false
  public myArray: Array<routeItem> = [
    {
      id: 0,
      orderNumber: 0,
      name: 'Keine Startadresse',
      type: 'start',
      fixed: true
    },
    {
      id: 40,
      orderNumber: 0,
      name: 'Keine Startadresse',
      type: 'customer',
      fixed: true,
      isTime: true
    },
    {
      id: 1,
      orderNumber: 1,
      name: 'Customer A',
      type: 'customer',
      fixed: false
    },
    {
      id: 41,
      orderNumber: 0,
      name: 'Keine Startadresse',
      type: 'customer',
      fixed: true,
      isTime: true
    },
    {
      id: 2,
      orderNumber: 2,
      name: 'Customer B',
      type: 'customer',
      fixed: false
    },
    {
      id: 10,
      orderNumber: 0,
      name: 'Via Adresse',
      type: 'via',
      fixed: false
    },
    {
      id: 3,
      orderNumber: 3,
      name: 'Customer C',
      type: 'customer',
      fixed: false
    },
    {
      id: 4,
      orderNumber: 4,
      name: 'Customer D',
      type: 'customer',
      fixed: false
    },
    {
      id: 5,
      orderNumber: 5,
      name: 'Customer E',
      type: 'customer',
      fixed: false
    },
    {
      id: 6,
      orderNumber: 6,
      name: 'Customer F',
      type: 'customer',
      fixed: false
    },
    {
      id: 7,
      orderNumber: 7,
      name: 'Customer G',
      type: 'customer',
      fixed: false
    },
    {
      id: 8,
      orderNumber: 8,
      name: 'Customer H',
      type: 'customer',
      fixed: false
    },
    {
      id: 9,
      orderNumber: 9,
      name: 'Keine Zieladresse',
      type: 'finish',
      fixed: true
    }
  ]

  public dragOptions = {
    animation: 200,
    group: 'locations',
    disabled: false,
    ghostClass: 'ghost',
    draggable: '.is-draggable'
  }

  public onSettings (): void {
    console.log('Settings')
  }

  public onUpdate (event: MoveEvent<routeItem>): void {
    console.log(event)
    const draggedContext = event.draggedContext
    const from = draggedContext.index
    const to = draggedContext.futureIndex
    console.log('moved from: ', from, ' to: ', to)
    // const newList = [...this.myArray].map((item, index) => {
    //   const newSort = index + 1
    //   // also add in a new property called has changed if you want to style them / send an api call
    //   item.hasChanged = item.sortOrder !== newSort;
    //   if (item.hasChanged) {
    //     item.sortOrder = newSort;
    //   }
    //   return item;
    // });
    // this.lineItems = newList;
  }

  public onRouteItemSelected (item: routeItem): void {
    this.myArray.map(i => {
      item.id !== i.id ? i.selected = false : i.selected = item.selected
    })
    item.selected = !item.selected
    this.$forceUpdate()
  }
}
