
export interface ILocalStorageItem {
  key: string;
  value: string | null;
}

export class LocalStorageItem {
  public key: string
  public value: string | null

  constructor (data: ILocalStorageItem) {
    this.key = data.key
    this.value = data.value
  }
}

export class LocalStorageWorker {
  public isLocalStorageSupported: boolean

  constructor () {
    this.isLocalStorageSupported = typeof window.localStorage !== 'undefined' && window.localStorage !== null
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new
   * key/value pair if none existed for key previously. Throws a
   * `QuotaExceededError` DOMException exception if the new value couldn't be
   * set. (Setting could fail if, e.g., the user has disabled storage for the
   * site, or if the quota has been exceeded.)
   * @param key The localStorage items key.
   * @param value The localStorage items value.
   */
  public add (key: string, value: string): void {
    if (this.isLocalStorageSupported) {
      localStorage.setItem(key, value)
    }
  }

  /**
   * Get all items stored in localStorage.
   * @returns A list containing all localStorage items.
   */
  public getAllItems (): Array<LocalStorageItem> {
    const list = new Array<LocalStorageItem>()

    for (let index = 0; index < localStorage.length; index++) {
      const key = localStorage.key(index)
      if (key) {
        const value = localStorage.getItem(key)
        list.push(new LocalStorageItem({ key: key, value: value }))
      }
    }

    return list
  }

  /**
   * Get all values stored in localStorage. This method will not return keys.
   * @returns A list containing all localStorage values only.
   */
  public getAllValues (): Array<string | null> {
    const list = new Array<string | null>()

    for (let index = 0; index < localStorage.length; index++) {
      const key = localStorage.key(index)
      if (key) {
        const value = localStorage.getItem(key)
        list.push(value)
      }
    }

    return list
  }

  /**
   * Returns the current value associated with the given key, or null if the
   * given key does not exist in the list associated with the object.
   * @param key The localStorage items key.
   * @returns The corresponding value.
   */
  public get (key: string): string | null {
    if (this.isLocalStorageSupported) {
      return localStorage.getItem(key)
    }
    return null
  }

  /**
   * Removes the key/value pair with the given key from the list associated
   * with the object, if a key/value pair with the given key exists.
   * @param key The localStorage items key.
   */
  public remove (key: string): void {
    if (this.isLocalStorageSupported) {
      localStorage.removeItem(key)
    }
  }

  /**
   * Empties the list associated with the object of all key/value pairs, if
   * there are any.
   */
  public clear (): void {
    if (this.isLocalStorageSupported) {
      localStorage.clear()
    }
  }
}

interface ISidebarStorage {
  collapsed: boolean
}

export class SidebarStorage {
  private storageWorker: LocalStorageWorker
  private storageKey: string
  private sidebarConfig: ISidebarStorage

  constructor (storageKey?: string) {
    this.storageWorker = new LocalStorageWorker()
    this.storageKey = storageKey ?? 'sidebar'
    this.sidebarConfig = this.config
  }

  public get config (): ISidebarStorage {
    const items = this.storageWorker.get(this.storageKey)
    if (items) {
      return JSON.parse(items)
    }
    return {
      collapsed: false
    }
  }

  /**
   * Sets a new value for the collapsed property.
   * @param collapsed The new collapsed value.
   */
  public addSidebarCollapsed (collapsed: boolean): void {
    this.sidebarConfig.collapsed = collapsed
    this.save()
  }

  /**
   * Removes all stored entries for the sidebar storage.
   */
  public clear (): void {
    this.storageWorker.remove(this.storageKey)
  }

  /**
   * Save the current `sidebarConfig` to localStorage
   */
  public save (): void {
    const jsonSidebar = JSON.stringify(this.sidebarConfig)
    this.storageWorker.add(this.storageKey, jsonSidebar)
  }
}

interface ILocaleStorage {
  locale: string | null
}

export class LocaleStorage {
  private storageWorker: LocalStorageWorker
  private storageKey: string
  private localeConfig: ILocaleStorage

  constructor (storageKey?: string) {
    this.storageWorker = new LocalStorageWorker()
    this.storageKey = storageKey ?? 'localization'
    this.localeConfig = this.config
  }

  public get config (): ILocaleStorage {
    const items = this.storageWorker.get(this.storageKey)
    if (items) {
      return JSON.parse(items)
    }
    return {
      locale: null
    }
  }

  /**
   * Sets a new value for the locale property.
   * @param locale The new collapsed value.
   */
  public addLocale (locale: string): void {
    this.localeConfig.locale = locale
    this.save()
  }

  /**
   * Removes all stored entries for the sidebar storage.
   */
  public clear (): void {
    this.storageWorker.remove(this.storageKey)
  }

  /**
   * Save the current `localeConfig` to localStorage
   */
  public save (): void {
    const jsonConfig = JSON.stringify(this.localeConfig)
    this.storageWorker.add(this.storageKey, jsonConfig)
  }
}

interface IThemeStorage {
  theme: string | null
}

export class ThemeStorage {
  private storageWorker: LocalStorageWorker
  private storageKey: string
  private themeConfig: IThemeStorage

  constructor (storageKey?: string) {
    this.storageWorker = new LocalStorageWorker()
    this.storageKey = storageKey ?? 'theme'
    this.themeConfig = this.config
  }

  public get config (): IThemeStorage {
    const items = this.storageWorker.get(this.storageKey)
    if (items) {
      return JSON.parse(items)
    }
    return {
      theme: null
    }
  }

  /**
   * Sets a new value for the theme property.
   * @param theme The new theme value.
   */
  public addTheme (theme: string): void {
    this.themeConfig.theme = theme
    this.save()
  }

  /**
   * Removes all stored entries for the theme storage.
   */
  public clear (): void {
    this.storageWorker.remove(this.storageKey)
  }

  /**
   * Save the current `themeConfig` to localStorage
   */
  public save (): void {
    const jsonConfig = JSON.stringify(this.themeConfig)
    this.storageWorker.add(this.storageKey, jsonConfig)
  }
}
