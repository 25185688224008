import moment from 'moment'

export enum EDriverStatus{
  offline = 'OFFLINE',
  online = 'ONLINE',
  blocked = 'BLOCKED',
  confirm = 'CONFIRM'
}

export interface IDriver {
  key: string
  firstname: string
  lastname: string
  image: string
  phone: string
  email: string
  lastOnline: moment.Moment
  status: EDriverStatus
  isDriving: boolean
  created: moment.Moment
}
