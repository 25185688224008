















































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { getStorage, ref as stRef, UploadMetadata, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { FirebaseAppInstance } from '@/data/app'
import constants from '@/common/constants'
import FileResize from '@/common/fileResize'

@Component
export default class UploadProfileImageForm extends Vue {
  @Prop() relatedKey!: string
  @Prop() dbReference!: string

  public imageFile: File | null = null
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public isUploading = false
  public uploadPercentage = 0
  public responseError: string | null = null
  public showResponseAlert = false

  get stateProfileImage (): false | null {
    return this.formCheck.findFormError('profileImage') ? false : null
  }

  /**
   * Upload the provided image.
   */
  public submitUploadProfileImage (): void {
    this.isLoading = true
    if (!this.checkForm() && this.imageFile) {
      this.isUploading = true
      // FleetHandler.uploadProfileImage(this.relatedKey, this.imageFile)
      const fileResize = new FileResize()
      fileResize.resizeImage(this.imageFile, 400, 400, this.imageFile.name).then(resizedFile => {
        // console.log('RESIZED:')
        // console.log(resized)
        this.uploadProfileImage(this.dbReference, resizedFile)
      })
      // console.log('ORIGINAL')
      // console.log(this.imageFile)
    } else {
      this.isLoading = false
    }
  }

  public uploadProfileImage (dbRefValue: string, image: File): void {
    this.isUploading = true
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const st = getStorage(app)
    const storageRef = stRef(st, dbRefValue)

    const metadata: UploadMetadata = {
      contentType: image.type
    }

    // const uploadTask = stRef(storageRef, this.relatedKey).put(image, metadata)
    const uploadTask = uploadBytesResumable(stRef(storageRef, this.relatedKey), image, metadata)

    uploadTask.on('state_changed', (snapshot) => {
      this.uploadPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      switch (snapshot.state) {
        case 'paused':
          // Paused
          break
        case 'running':
          // Running
          break
      }
    }, (error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          // Not authorized
          break
        case 'storage/canceled':
          // Cancelled
          break
        case 'storage/unknown':
          // Unknown storage
          break
      }
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        this.$emit('finished-image-upload', this.relatedKey, downloadURL)
        this.resetForm()
        // TODOS:
        // tour_id & driver_id: /vehicles/$vehicle_id/tours/[$tour_id]/driver
        // UPDATE: /users/$driver_id/tours/$tour_id/vehicle/image => downloadURL
        // UPDATE: /users/$driver_id/tours/$tour_id/vehicle/imageDate => firebase.database.ServerValue.TIMESTAMP
        //

        // for (const tour in selectedVehicle.tours) {
        //   if ( selectedVehicle.tours.hasOwnProperty( tour ) ) {
        //     const dict = selectedVehicle.tours[tour];
        //     firebase.database().ref( '/users/' + dict.driver + '/tours/' + dict.tour + '/vehicle/' ).update({
        //         image: downloadURL ? downloadURL : null,
        //         imageDate: firebase.database.ServerValue.TIMESTAMP,
        //     }, function ( error ) {
        //         if ( error ) {
        //             _self._showErrorNotification( error.message );
        //         }
        //     });
        //   }
        // }
      })
    })
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkFileRequired('profileImage', this.imageFile)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form inputs and errors.
   */
  public resetForm (): void{
    this.formCheck.clearFormErrors()
    this.imageFile = null
    this.isLoading = false
    this.isUploading = false
  }

  /**
   * Cancel form. This method will emit the `cancelled-image-form`-event.
   */
  public cancelForm (): void {
    this.resetForm()
    this.$emit('cancelled-image-form')
  }
}
