import FirebaseAuthenticationErrors from '@/plugins/firebase/authenticationErrors'
import FirebaseDatabaseErrors from '@/plugins/firebase/DatabaseErrors'

export class ResponseError extends Error {
  constructor (code: string, message: string) {
    super()
    this.name = code
    this.message = message
  }

  /**
   * This method safely returns an existing locale key.
   *
   * This method checks the errors code for existence in a list of
   * authentication errors based on firebase auth. If no key is found, the
   * `message` attribute will be returned. If this does not exist either, the
   * `unexpected-error` key is returned.
   * @returns The locale key for a corresponding error message
   */
  public getErrorLocaleKey (): string {
    const errorCode = this.name.split('/')[1] ?? this.name
    const localeKey = FirebaseAuthenticationErrors.authenticationErrorLocaleKeyByCode(errorCode)
    return localeKey ?? this.message ?? 'unexpected-error'
  }

  public getDatabaseErrorLocaleKey (): string {
    const errorCode = this.name.split('/')[1] ?? this.name
    const localeKey = FirebaseDatabaseErrors.databaseErrorLocaleKeyByCode(errorCode)
    return localeKey ?? this.message ?? 'UNEXPECTED_ERROR'
  }
}
