import constants from '@/common/constants'
import { FirebaseAppInstance } from '@/data/app'
import {
  DatabaseReference, DataSnapshot,
  getDatabase, ref, off,
  onChildAdded, onChildChanged, onChildRemoved
} from 'firebase/database'
import { CategoryObject } from '../'
import { categoriesStore } from './categoriesStore'
import { CategoryCustomer } from './category'

export class CategoriesInstance {
  private static instance: CategoriesInstance
  private categoriesRef: DatabaseReference
  public isListeningForCategories = false

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.categoriesRef = ref(db, constants.DB_CATEGORIES)
  }

  public static getInstance (): CategoriesInstance {
    if (!CategoriesInstance.instance) {
      CategoriesInstance.instance = new CategoriesInstance()
    }
    return CategoriesInstance.instance
  }

  /**
   * Start listening for changes of categories in the database.
   *
   * This includes listening on the `childAdded`, `childChanged` and
   * `childRemoved` -events. The categories are then accessable by using the
   * `categories`-getter in the `categoriesStore`.
   *
   * To stop listening for these events use the `stopListeningForCategories`
   * method.
   */
  public startListeningForCategories (): void {
    this.stopListeningForCategories()
    this.isListeningForCategories = true

    // Child added
    onChildAdded(this.categoriesRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        categoriesStore.add(this.convertSnapshotToCategory(snapshot, snapshot.key))
      }
    })

    // Child changed
    onChildChanged(this.categoriesRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const newCategory = this.convertSnapshotToCategory(snapshot, snapshot.key)
        categoriesStore.update(newCategory)
      }
    })

    // Child removed
    onChildRemoved(this.categoriesRef, (snapshot) => {
      categoriesStore.remove(snapshot.key)
    })
  }

  /**
   * Stop listening for changes of categories in the database.
   */
  public stopListeningForCategories (): void {
    off(this.categoriesRef)
    categoriesStore.clearData()
    this.isListeningForCategories = false
  }

  /**
   * Convert a firebase `DataSnapshot` to a CategoryObject.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted CategoryObject.
   */
  private convertSnapshotToCategory (snapshot: DataSnapshot, key: string): CategoryObject {
    const snapshortCustomers = snapshot.val().customers as Record<string, boolean>
    const categoryCustomers = this.convertObjectCustomersToArray(snapshortCustomers)

    const category = snapshot.val() as CategoryObject
    category.key = key
    category.customers = categoryCustomers
    return category
  }

  private convertObjectCustomersToArray (objectCustomers: Record<string, boolean>): Array<CategoryCustomer> {
    const categoryCustomers: Array<CategoryCustomer> = []
    if (objectCustomers) {
      for (const index in objectCustomers) {
        const categoryCustomer: CategoryCustomer = {
          key: index,
          isCustomer: objectCustomers[index]
        }
        categoryCustomers.push(categoryCustomer)
      }
    }
    return categoryCustomers
  }
}
