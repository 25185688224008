
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MediaListItem extends Vue {
  @Prop(String) imageSrc!: string
  @Prop(String) title!: string
  @Prop(String) subtitle!: string
  @Prop(String) caption!: string
  @Prop(String) localeTitle!: string
  @Prop(String) localeSubtitle!: string
  @Prop(String) localeCaption!: string
  @Prop(String) link!: string
  @Prop({ default: true, type: Boolean }) isClickable!: boolean
  @Prop({ default: false, type: Boolean }) isSelected!: boolean
  @Prop({ default: 'secondary', type: String }) variant!: string
  @Prop({ default: 'person-fill', type: String }) icon!: string
  @Prop({ default: '2.5rem', type: String }) iconSize!: string
  @Prop({ default: 'li', type: String }) tag!: string
}
