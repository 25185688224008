























































import { AuthenticationInstance } from '@/data/authentication'
import { authenticationStore, AuthenticationUser } from '@/store/modules/authenticationStore'
import LocalizationStore from '@/store/modules/LocalizationStore'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SubHeader extends Vue {
  public countryIconProps = { height: 24, width: 24 }

  get isUserAuth (): boolean {
    return authenticationStore.userAuth
  }

  get user (): AuthenticationUser | null {
    return authenticationStore.user
  }

  get profileImage (): string | null {
    return authenticationStore.relatedUserData?.imageSrc ?? null
  }

  get initials (): string | null {
    const first = authenticationStore.relatedUserData?.firstname?.charAt(0)
    const last = authenticationStore.relatedUserData?.lastname?.charAt(0)
    return `${first}${last}`
  }

  /**
   * Switch the global app locale.
   */
  public switchLocale (localeKey: string): void {
    LocalizationStore.changeLocale(localeKey)
  }

  /**
   * Sign out the currently signed in user.
   */
  public signOut (): void {
    AuthenticationInstance.getInstance().signOutFromApp()
  }
}
