
































import SidebarStore from '@/store/modules/SidebarStore'
import { Component, Vue, Watch } from 'vue-property-decorator'
import NavigationItem from './lib/NavigationItem'

@Component
export default class SidebarItems extends Vue {
  public activeItem = ''
  public navigationItems: NavigationItem[] = [
    new NavigationItem('dashboard', 'house', 'componentSidebar.dashboard', '/'),
    new NavigationItem('customers', 'building', 'componentSidebar.customers'),
    new NavigationItem('fleet', 'truck', 'componentSidebar.fleet'),
    new NavigationItem('drivers', 'person-badge', 'componentSidebar.drivers'),
    new NavigationItem('sensors', 'cpu', 'componentSidebar.sensors'),
    new NavigationItem('tours', 'clipboard', 'componentSidebar.tours'),
    new NavigationItem('plan-tour', 'clipboard-plus', 'componentSidebar.planTour'),
    new NavigationItem('calendar', 'calendar-3', 'componentSidebar.calendar'),
    new NavigationItem('settings', 'gear', 'componentSidebar.settings')
  ]

  public supportItems: NavigationItem[] = [
    new NavigationItem('support', 'headset', 'componentSidebar.support'),
    new NavigationItem('archive', 'archive', 'componentSidebar.archive')
  ]

  public legalItems: NavigationItem[] = [
    new NavigationItem('imprint', 'file-text', 'componentSidebar.imprint'),
    new NavigationItem('privacy', 'shield-check', 'componentSidebar.privacy')
  ]

  /**
   * Get the current `collapsed` state.
   */
  get isSidebarCollapsed (): boolean {
    return SidebarStore.collapsed
  }

  /**
   * Check if the given name is currently active.
   * @param itemName The router name.
   * @returns `true` or `false`
   */
  public isActive (itemName: string): boolean {
    return this.activeItem === itemName
  }

  /**
   * Set the navigation item with the specified name to be active.
   * @param itemName The router name.
   */
  public setActive (itemName: string): void {
    this.activeItem = itemName
  }

  @Watch('$route.name', { deep: true, immediate: true })
  onRouteNameChanged (name: string): void {
    name = name.replaceAll('Admin', '')
    this.setActive(name ?? '')
  }
}
