import moment, { Moment } from 'moment'
import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '../'

@Module()
class SetupStore extends VuexModule {
  public isInitialized = false
  public isConnected = false
  public created: Moment | null = null
  public mapquestKey: string | null = null
  public validationKey: string | null = null

  get initialized (): boolean {
    return this.isInitialized
  }

  get connected (): boolean {
    return this.isConnected
  }

  /**
   * Set the value for the `isInitialized` property.
   * @param initialized The new `isInitialized` value.
   */
  @Mutation setInitialized (initialized: boolean): void {
    this.isInitialized = initialized
  }

  /**
   * Set the value for the `isConnected` property.
   * @param connected The new `isConnected` value.
   */
  @Mutation setConnected (connected: boolean): void {
    this.isConnected = connected
  }

  @Mutation setValidationKey (validationKey: string | null): void {
    this.validationKey = validationKey
  }

  @Mutation setMapquestKey (mapquestKey: string | null): void {
    this.mapquestKey = mapquestKey
  }

  @Mutation setCreated (created: number | null): void {
    this.created = moment(created)
  }

  @Mutation clearSetupData (): void {
    this.mapquestKey = null
    this.validationKey = null
  }
}

export default new SetupStore({ store: store, name: 'setup' })
