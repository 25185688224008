
type DatabaseErrorType = {
  [index: string]: string
}

const params: DatabaseErrorType = {
  PERMISSION_DENIED: 'databaseError.permissionDenied',
  UNEXPECTED_ERROR: 'databaseError.unexpectedError',
  'sensor-already-registered': 'databaseError.sensorAlreadyRegistered',
  'serial-key-not-found': 'databaseError.serialKeyNotFound'
}

export default class FirebaseDatabaseErrors {
  static databaseErrorLocaleKeyByCode (code: string): string | undefined {
    return params[code]
  }
}
