
interface NavigationItemObject {
  name: string;
  icon: string;
  target: string;
  localeString: string;
}

export default class NavigationItem implements NavigationItemObject {
  readonly name: string
  readonly icon: string
  readonly target: string
  readonly localeString: string

  /**
   * This class describes a navigation item for the sidebar- menu.
   * @param name - The router name, this must not be a path.
   * @param icon -  The icon to be shown in the navigation bar.
   * @param localeString - The localization string defined using VueI18n.
   * @param target - The router name of the desired target. This must not be a path.
   */
  constructor (name: string, icon: string, localeString: string, target?: string) {
    this.name = name
    this.icon = icon
    this.target = target ?? name
    this.localeString = localeString
  }
}
