












import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ToursVueComponent extends Vue {
  get items (): Array<Record<string, string>> {
    return [
      {
        heading1: 'table cell',
        heading2: 'table cell',
        heading3: 'table cell',
        heading4: 'table cell',
        heading5: 'table cell',
        heading6: 'table cell',
        heading7: 'table cell',
        heading8: 'table cell',
        heading9: 'table cell',
        heading10: 'table cell',
        heading11: 'table cell',
        heading12: 'table cell'
      },
      {
        heading1: 'table cell',
        heading2: 'table cell',
        heading3: 'table cell',
        heading4: 'table cell',
        heading5: 'table cell',
        heading6: 'table cell',
        heading7: 'table cell',
        heading8: 'table cell',
        heading9: 'table cell',
        heading10: 'table cell',
        heading11: 'table cell',
        heading12: 'table cell'
      },
      {
        heading1: 'table cell',
        heading2: 'table cell',
        heading3: 'table cell',
        heading4: 'table cell',
        heading5: 'table cell',
        heading6: 'table cell',
        heading7: 'table cell',
        heading8: 'table cell',
        heading9: 'table cell',
        heading10: 'table cell',
        heading11: 'table cell',
        heading12: 'table cell'
      }
    ]
  }
}
