import { CompanyObject } from '@/data/company'
import moment from 'moment'
import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '../'

@Module()
class CompanyStore extends VuexModule {
  public companyObject: CompanyObject | null = null

  get company (): CompanyObject | null {
    return this.companyObject
  }

  get createdMoment (): moment.Moment | null {
    return this.companyObject ? moment(this.companyObject.created) : null
  }

  @Mutation setCompany (company: CompanyObject | null): void {
    this.companyObject = company
  }
}

export default new CompanyStore({ store: store, name: 'company' })
