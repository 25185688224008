import { Module } from 'vuex-class-modules'
import store from '@/store'
import { CategoriesInstance, CategoryObject } from '../'
import { DataStore } from '@/store/lib/dataStore'

@Module
export class CategoriesStore extends DataStore<CategoryObject> {
  get categories (): Array<CategoryObject> {
    return this.data
  }

  protected startListening (): void {
    if (!this.isRestricted) {
      const categoriesInstance = CategoriesInstance.getInstance()
      if (!categoriesInstance.isListeningForCategories) {
        categoriesInstance.startListeningForCategories()
      }
    } else {
      this.stopListening()
    }
  }

  protected stopListening (): void {
    const categoriesInstance = CategoriesInstance.getInstance()
    categoriesInstance.stopListeningForCategories()
  }
}

export const categoriesStore = new CategoriesStore({ store: store, name: 'categories' })
