



































































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ArchiveVueComponent extends Vue {
  public isMoreAvailable = true
  public fields = [
    {
      key: 'created',
      label: this.$i18n.tc('table.archive.created'),
      sortable: false
    },
    {
      key: 'orderNumber',
      label: this.$i18n.tc('table.archive.orderNumber'),
      sortable: false
    },
    {
      key: 'tourNumber',
      label: this.$i18n.tc('table.archive.tourNumber'),
      sortable: false
    },
    {
      key: 'departure',
      label: this.$i18n.tc('table.archive.departure'),
      sortable: false
    },
    {
      key: 'filename',
      label: this.$i18n.tc('table.archive.filename'),
      sortable: false
    },
    {
      key: 'filesize',
      label: this.$i18n.tc('table.archive.filesize'),
      sortable: false
    },
    {
      key: 'actions',
      label: '',
      thStyle: 'width:137px;',
      sortable: false
    }
  ]

  public items = [
    { created: '01.01.2001 08:00 Uhr', orderNumber: '28347', tourNumber: '38457', departure: '01.01.2001 08:00 Uhr' },
    { created: '01.01.2001 08:00 Uhr', orderNumber: '28347', tourNumber: '38457', departure: '01.01.2001 08:00 Uhr' },
    { created: '01.01.2001 08:00 Uhr', orderNumber: '28347', tourNumber: '38457', departure: '01.01.2001 08:00 Uhr' },
    { created: '01.01.2001 08:00 Uhr', orderNumber: '28347', tourNumber: '38457', departure: '01.01.2001 08:00 Uhr' }
  ]

  public loadMore (): void {
    this.items.push({
      created: '01.01.2001 08:00 Uhr', orderNumber: '28347', tourNumber: '38457', departure: '01.01.2001 08:00 Uhr'
    })
  }

  @Watch('$root.$i18n.locale')
  onLocaleChange (): void {
    // Locale changed
    this.$emit('bv::refresh::table')
  }
}
