



















































































































import Vue from 'vue'
export default Vue.extend({
  data () {
    return {
      defaultStay: 5,
      selectedRouteCalculation: null,
      routeCalculationOptions: [
        {
          value: 'null',
          text: 'Select an option',
          disabled: true
        },
        {
          value: 'fastest',
          text: 'Schnellste'
        },
        {
          vaule: 'shortest',
          text: 'Kürzeste'
        },
        {
          vaule: 'pedestrian',
          text: 'zu Fuß'
        },
        {
          vaule: 'bicycle',
          text: 'Fahrrad'
        }
      ],
      selectedRouteUnit: null,
      routeUnitOptions: [
        {
          value: 'null',
          text: 'Select an option',
          disabled: true
        },
        {
          value: 'm',
          text: 'in Meilen'
        },
        {
          vaule: 'k',
          text: 'in Kilometern'
        }
      ],
      selectedRouteLocale: null,
      routeLocaleOptions: [
        {
          value: 'null',
          text: 'Select an option',
          disabled: true
        },
        {
          value: 'en_US',
          text: 'English (US)'
        },
        {
          vaule: 'en_GB',
          text: 'English (Great Britain)'
        },
        {
          vaule: 'fr_CA',
          text: 'French (Canada)'
        },
        {
          vaule: 'fr_FR',
          text: 'French (France)'
        },
        {
          vaule: 'de_DE',
          text: 'German (Germany)'
        },
        {
          vaule: 'es_ES',
          text: 'Spanish (Spain)'
        },
        {
          vaule: 'es_MX',
          text: 'Spanish (Mexico)'
        },
        {
          vaule: 'ru_RU',
          text: 'Russian (Russia)'
        }
      ],
      selectedRoutePublish: false,
      selectedRouteHideCustomers: false
    }
  },
  methods: {

  }
})
