
export default class FileResize {
  public resizeImage (file: File, maxWidth: number, maxHeight: number, name: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () => {
        const width = image.width
        const height = image.height
        const fileName = name
        const imageType = file.type

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file)
        }

        const scale = Math.min((maxWidth / width), (maxHeight / height))
        const scaledImageWidth = width * scale
        const scaledImageHeight = height * scale

        const canvas = document.createElement('canvas')
        canvas.width = scaledImageWidth
        canvas.height = scaledImageHeight

        const context = canvas.getContext('2d')
        if (context) {
          context.drawImage(image, 0, 0, scaledImageWidth, scaledImageHeight)
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], fileName, {
                type: imageType,
                lastModified: Date.now()
              })
              resolve(resizedFile)
            } else {
              reject(new Error('no blob generated'))
            }
          }, 'image/jpeg', 1)
        } else {
          reject(new Error('no context'))
        }
      }
    })
  }
}
