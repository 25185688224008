












































import { Component, Vue } from 'vue-property-decorator'
import { ForgotPassword } from '@/data/authentication'
import { ResponseError } from '@/data/error'
import FormCheck from '@/common/formCheck'

@Component
export default class ForgotPasswordForm extends Vue {
  public email = ''
  public formCheck: FormCheck = new FormCheck()
  public responseError: string | null = null
  public showResponseAlert = false
  public showSuccessAlert = false
  public isLoading = false

  get stateEmail (): false | null {
    return this.formCheck.findFormError('email') ? false : null
  }

  /**
   * Submit forgot password form.
   *
   * This methods calls the `forgotPassword` method, if all input values are
   * valid.
   */
  public submitForgotPasswordForm (): void {
    if (!this.checkForm()) {
      this.forgotPassword()
    }
  }

  /**
   * Request forgot password email
   */
  public forgotPassword (): void {
    this.setLoading(true)
    const forgotPassword = new ForgotPassword(this.email)
    forgotPassword.sendPasswordResetEmail().then(() => {
      this.clearForm(true)
      this.showSuccessAlert = true
    }).catch((error: ResponseError) => {
      this.responseError = error.getErrorLocaleKey()
      this.showResponseAlert = true
    }).finally(() => {
      this.setLoading(false)
    })
  }

  /**
   * Check the form input against rules before submitting
   */
  public checkForm (): boolean {
    this.clearForm()
    this.formCheck.checkTextRequired('email', this.email)
    this.formCheck.checkEmailFormat('email', this.email)
    return this.formCheck.hasErrors()
  }

  /**
   * Clear all form properties.
   *
   * @param clearInputs Whether to clear inputs as well.
   */
  public clearForm (clearInputs = false): void {
    this.formCheck.clearFormErrors()
    this.responseError = null
    this.showResponseAlert = false

    if (clearInputs) {
      this.email = ''
    }
  }

  /**
   * Set a value for the `isLoading` property.
   *
   * This method will emit the `forgot-password-is-loading`-event.
   */
  public setLoading (isLoading: boolean): void {
    this.isLoading = isLoading
    this.$emit('forgot-password-is-loading', this.isLoading)
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
