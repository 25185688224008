



































import { Component, Vue } from 'vue-property-decorator'
import MediaList from '@/components/MediaList/MediaList.vue'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import ContactForm from '@/components/forms/ContactForm.vue'
import FaqAccordion from '@/components/FAQ/FaqAccordion.vue'
import FaqAccordionItem from '@/components/FAQ/lib/FaqAccordionItem'

@Component({
  components: { MediaList, ContactForm, FaqAccordion }
})
export default class SupportVueComponent extends Vue {
  public contactPerson = this.$t('componentSupport.contactPerson', { fullname: 'Oliver Adameck' })

  get faqItems (): FaqAccordionItem[] {
    return [{
      question: 'Frage 1',
      answer: 'Antwort 1',
      category: 'question-1'
    }, {
      question: 'Frage 2',
      answer: 'Antwort 2',
      category: 'question-2'
    }]
  }

  get supportMediaItems (): IMediaListItem[] {
    const supportItems: IMediaListItem[] = [{
      key: 'call',
      title: '(+49) 176 / 845 915 15',
      localeSubtitle: 'componentSupport.phone',
      icon: 'telephone-inbound',
      variant: 'primary'
    }, {
      key: 'email',
      title: 'adameck@emuwa.de',
      localeSubtitle: 'componentSupport.email',
      icon: 'envelope',
      variant: 'primary'
    }]
    return supportItems
  }
}
