




















import { Component, Prop, Vue } from 'vue-property-decorator'
import AddCategoryForm from '@/components/categories/AddCategoryForm.vue'
import SimpleStatisticSkeleton from '@/components/skeletons/SimpleStatisticSkeleton.vue'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { AddCategoryForm, SimpleStatisticSkeleton }
})
export default class SimpleStatistic extends Vue {
  @Prop(String) title!: string
  @Prop(Number) metrics!: number
  @Prop(String) variant!: string
  @Prop(String) icon!: string

  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }
}
