











































































































































import { Component, Vue } from 'vue-property-decorator'
import MediaList from '@/components/MediaList/MediaList.vue'
import SimpleStatistic from '@/components/statistics/SimpleStatistic.vue'
import CompanyStore from '@/store/modules/CompanyStore'
import { AuthenticationInstance } from '@/data/authentication'
import { ResponseError } from '@/data/error'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { MediaList, SimpleStatistic }
})
export default class DashboardVueComponent extends Vue {
  public amountTours = 0
  public amountCustomers = 0
  public amountDrivers = 0
  public amountFleet = 0
  public isLoading = false

  get isEmailVerified (): boolean {
    return authenticationStore.user?.emailVerified ?? false
  }

  get isNoAdministratorExisting (): boolean {
    return authenticationStore.isNoAdministratorExisiting
  }

  get isAccountConfirmed (): boolean {
    return authenticationStore.relatedUserData?.confirmed ?? false
  }

  get isAdministrator (): boolean {
    return authenticationStore.isAdministrator ?? false
  }

  get isCompanyName (): boolean {
    return (CompanyStore.company?.name ?? '') !== ''
  }

  get isCompanyEmail (): boolean {
    return (CompanyStore.company?.email ?? '') !== ''
  }

  get isCompanyAddress (): boolean {
    return (CompanyStore.company?.address ?? '') !== ''
  }

  get isCompanyPhone (): boolean {
    return (CompanyStore.company?.phone ?? '') !== ''
  }

  get isCompanyProfileComplete (): boolean {
    if (CompanyStore.company) {
      return this.isCompanyName &&
        this.isCompanyEmail &&
        this.isCompanyAddress &&
        this.isCompanyPhone
    }
    return false
  }

  get isProfileCompleted (): boolean {
    return this.isEmailVerified &&
      this.isAccountConfirmed &&
      this.isAdministrator &&
      this.isCompanyProfileComplete
  }

  public onResendVerificationMail (): void {
    this.isLoading = true
    const authenticationInstance = AuthenticationInstance.getInstance()
    authenticationInstance.resendVerificationMail().then(() => {
      this.$root.$bvToast.toast(this.$tc('authentication.emailVerificationCard.notification.message'), {
        title: this.$tc('authentication.emailVerificationCard.notification.title'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'success'
      })
    }).catch((error) => {
      if (error.code) {
        error.name = error.code
      }
      const responseError = new ResponseError(error.name, error.message)
      const errorLocaleKey = responseError.getErrorLocaleKey()
      this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
        title: this.$tc('authentication.emailVerificationCard.notification.title'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'danger'
      })
    }).finally(() => {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    })
  }

  public onConfirmAdministratorConversion (): void {
    this.isLoading = true
    const authenticationInstance = AuthenticationInstance.getInstance()
    authenticationInstance.convertAccountToAdministrator(this.isNoAdministratorExisting).then(() => {
      this.$root.$bvToast.toast(this.$tc('authentication.adminPendingRoleCard.notification.message'), {
        title: this.$tc('authentication.adminPendingRoleCard.notification.title'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'success'
      })
    }).catch((error) => {
      if (error.code) {
        error.name = error.code
      }
      const responseError = new ResponseError(error.name, error.message)
      const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
      this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
        title: this.$tc('authentication.adminPendingRoleCard.notification.title'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'danger'
      })
    }).finally(() => {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    })
  }

  public loadStatistics (): void {
    // Load statistics
  }

  public reloadPage (): void {
    window.location.reload()
  }
}
