















import { Component, Vue } from 'vue-property-decorator'
import MediaListSkeleton from '@/components/skeletons/MediaListSkeleton.vue'
import MediaList from '@/components/MediaList/MediaList.vue'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { MediaListSkeleton, MediaList }
})
export default class DriverRegistrationCard extends Vue {
  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }

  get appsMediaItems (): Array<IMediaListItem> {
    return [{
      key: '01',
      localeTitle: 'driverRegistration.app.title',
      icon: 'phone',
      variant: 'dark',
      iconSize: '3rem',
      isClickable: false
    }]
  }
}
