






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SimpleStatisticSkeleton extends Vue {
  @Prop(Boolean) showRestrictedOverlay!: boolean | null
}
