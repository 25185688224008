





























































































import { Component, Vue } from 'vue-property-decorator'
import ValidationKeyCard from '@/components/validationKey/ValidationKeyCard.vue'
import RegistrationCard from '@/components/drivers/RegistrationCard.vue'
import { EDriverStatus, IDriver } from '@/data/drivers/lib/driver'
import { DriversHandler } from '@/data/drivers/lib/driversHandler'
import moment from 'moment'
import { authenticationStore } from '@/store/modules/authenticationStore'
import LocalizationStore from '@/store/modules/LocalizationStore'

class TableDriverItem implements IDriver {
  _rowVariant = 'danger'
  key: string
  firstname: string
  lastname: string
  image: string
  phone: string
  email: string
  lastOnline: moment.Moment
  status: EDriverStatus
  isDriving: boolean
  created: moment.Moment

  constructor (v: string, driver: IDriver) {
    this._rowVariant = v
    this.key = driver.key
    this.firstname = driver.firstname
    this.lastname = driver.lastname
    this.image = driver.image
    this.phone = driver.phone
    this.email = driver.email
    this.lastOnline = driver.lastOnline
    this.status = driver.status
    this.isDriving = driver.isDriving
    this.created = driver.created
  }
}

@Component({
  components: { ValidationKeyCard, RegistrationCard }
})
export default class DriversVueComponent extends Vue {
  private driversHandler: DriversHandler = new DriversHandler()
  public isLoadingDrivers = true

  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }

  get driverItems (): Array<TableDriverItem> {
    const items: Array<TableDriverItem> = []
    this.driversHandler.drivers.forEach(driver => {
      let variant = ''
      if (driver.status === EDriverStatus.blocked) {
        variant = 'danger'
      } else if (driver.status === EDriverStatus.confirm) {
        variant = 'warning'
      }
      items.push(new TableDriverItem(variant, driver))
    })
    return items
  }

  public fields = [{
    key: 'avatar',
    sortable: false
  }, {
    key: 'name',
    sortable: false,
    formatter: (value: string, key: string, item: TableDriverItem): string => {
      return this.formattedDriverName(item)
    }
  }, {
    key: 'phone',
    sortable: false
  }, {
    key: 'email',
    sortable: false
  }, {
    key: 'lastOnline',
    sortable: false,
    formatter: (value: string, key: string, item: TableDriverItem): string => {
      return this.formattedLastOnlineStatus(item)
    }
  }, {
    key: 'created',
    sortable: false,
    formatter: (value: moment.Moment): string => {
      return this.formattedDateString(value)
    }
  }, {
    key: 'actions',
    sortable: false
  }]

  public formattedDriverName (driver?: TableDriverItem): string {
    if (driver) {
      return `${driver.firstname} ${driver.lastname}`
    }
    return ''
  }

  public formattedLastOnlineStatus (driver?: TableDriverItem): string {
    if (driver) {
      const isOnline = driver.status === EDriverStatus.online
      if (isOnline) {
        return 'Online'
      } else {
        driver.lastOnline.locale(LocalizationStore.locale)
        return driver.lastOnline.isValid() ? driver.lastOnline.fromNow() : '-'
      }
    }
    return '-'
  }

  public formattedDateString (date: moment.Moment): string {
    return date.isValid() ? date.format('DD.MM.YYYY') : '-'
  }

  public statusVariant (status: EDriverStatus): string {
    switch (status) {
      case EDriverStatus.offline:
        return 'danger'
      case EDriverStatus.online:
        return 'primary'
      case EDriverStatus.blocked:
        return 'danger'
      case EDriverStatus.confirm:
        return 'warning'
      default:
        return 'danger'
    }
  }

  public onChangeActiveState (key: string, newState: boolean): void {
    this.driversHandler.changeActiveState(key, newState)
  }

  public onConfirmDriver (key: string): void {
    this.driversHandler.confirmDriverAccount(key)
  }

  public mounted (): void {
    // !!!! IMPORTANT NOTE: Adding `mapquest.css` results in a css style for the <form-control> - Tag !!!!
    // ====> This will affect all form-controls created by bootstrap and lead to misstyled forms !!!!!!!!!
    // Consider a removing strategy on `destroyed`-event.
    // const plugin = document.createElement('script')
    // plugin.setAttribute('src', 'https://api.mqcdn.com/sdk/mapquest-js/v1.3.2/mapquest.js')
    // plugin.async = true
    // document.head.appendChild(plugin)

    // const pluginCss = document.createElement('link')
    // pluginCss.setAttribute('href', 'https://api.mqcdn.com/sdk/mapquest-js/v1.3.2/mapquest.css')
    // pluginCss.setAttribute('rel', 'stylesheet')
    // pluginCss.setAttribute('type', 'text/css')
    // document.head.appendChild(pluginCss)

    this.driversHandler.startDriversListener()
    this.isLoadingDrivers = false
  }

  public destroyed (): void {
    this.driversHandler.stopDriversListener()
  }
}
